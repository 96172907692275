<template>
	<section class="domain_content">
    <h1>How to Use GB WhatsApp on Web or Desktop?</h1>
		<p>
			<img
				class="wi"
				alt="img"
				src="@/assets/blog6.webp" />
		</p>
    <p>
      In the modern world, staying connected is easier than ever with a wide range of messaging apps. WhatsApp, one of the most popular messaging apps, supports all platforms including Android, iOS, and the Web. 
    </p>
    <p>
      However, GBWhatsApp, a modified version of the original WhatsApp, is predominantly known for its Android support. GB WhatsApp Web is not available. But, there are methods to mirror your phone screen on a desktop. This article will guide you through the process of using GBWhatsApp on the web via screen mirroring.
    </p>
		<!-- 下载 -->
		<div
			exp-data="show_gbhome_download"
			class="big-download-btn top-download fix has-exp"
			data-exposure="1"
			@click="download(apk)">
			<img
				:src="downloadicon"
				alt="download"
				class="download-img" />
			<div class="download-name">GB WhatsApp Download</div>
		</div>

    <h2>
      Reflect GB WhatsApp on Desktop by Screen Mirroring App
    </h2>
    <p>
      Screen mirroring is a technique that allows you to display your smartphone's screen on a larger screen, such as a computer or television. By using a screen mirroring app, you can interact with GBWhatsApp on your desktop. Typing text messages is also supported. Follow these steps to get started:
    </p>

    <h3>
      Step 1: Choose a Screen Mirroring App
    </h3>
    <p>
      Many screen mirroring apps are available in the app store.You can choose one you like to start mirroring. 
    </p>
    <ol>
      <li>
        <strong>MirrorGo:</strong>
        Install it on both your Android and Desktop, you can then mirror GB WhatsApp to desktop. 
      </li>
      <li>
        <strong>ApowerMirror:</strong>
        A versatile app that supports both Android and iOS devices and allows for wireless or USB connection.
      </li>
    </ol>
    <h3>
      Step 2: Enable Developer Options and USB Debugging
    </h3>
    <p>
      To allow the screen mirroring app to work correctly, you need to enable Developer Options and USB Debugging on your Android device:
    </p>
    <ol>
      <li>
        <strong>Enable Developer Options:</strong>
        <p>The app may notice you how to enable it. Following their guide is OK.</p>
      </li>
      <li>
        <strong>Enable USB Debugging:</strong>
        <ul>
          <li>Go to <strong>Settings</strong> > <strong>System</strong> > <strong>Developer options.</strong></li>
          <li>Scroll down and toggle on <strong>USB debugging.</strong></li>
        </ul>
        <p>For a more detailed guide, you can check the tutorial of a mirroring app.</p>
      </li>
    </ol>

    <h3>Step 3: Connect Your Android Device to Your Desktop</h3>
    <ol>
      <li>
        <strong>Wireless Connection:</strong>
        <ul>
          <li>Set your Android device and desktop under the same Wi-Fi network.</li>
          <li>Open the screen mirroring app on both devices.</li>
          <li>Follow the on-screen instructions to connect your Android device to your desktop wirelessly.</li>
        </ul>
      </li>
      <li>
        <strong>USB Connection:</strong>
        <p>
          Connect your Android device to your desktop using a USB cable and then follow the guide on screen. 
        </p>
      </li>
    </ol>

    <h3>Step 4: Start Screen Mirroring</h3>
    <p>
      Once your devices are connected, the screen mirroring app will display your Android device's screen on your desktop. You can now open GBWhatsApp in a larger screen and reply messages with mouth and keyboard. 
    </p>
    <h2>Conclusion</h2>
    <p>
      Screen mirroring apps provide a viable alternative for using GB WhatsApp on desktop. By following the steps outlined above, you can enjoy the convenience of GBWhatsApp on a larger screen. We hope that GBWhatsApp will eventually release a web version to provide users with a more seamless experience. 
    </p>
		</section>
</template>

<script>
import '@/css/default.scss'
import { mapGetters } from 'vuex'
const title = 'Methods to Use GB WhatsApp on Web or Desktop'
const description = "We introduce an alternative method to help users use GBWhatsApp on a desktop, even though GBWhatsApp only supports the Android platform. Read on to learn more about it. "
export default {
	data() {
		return {
			from: null,
			filename: null,
			partners: null,
			updateTime: null,
			clicks: null,
			pageIntSuccess: null,
			apk: null,
		}
	},
	metaInfo: {
    title,
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        name: 'title',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
    ],
  },

	computed: {
		...mapGetters(['showDebug', 'host', 'downloadicon']),
	},
	created() {
		this.initParams()
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		download(apk) {
			this.$global.download(apk)
		},
		initParams() {
			this.from = this.$global.GetQueryString('from')
			this.filename = this.$global.GetQueryString('filename')
		},
		fetchData() {
			if (this.from) {
				this.getAPK(true, this.from, this.filename)
			} else {
				this.getAPK()
				this.getOfficialApk()
			}
		},
		updateAPK() {
			this.$emit('update-apk', this.apk)
		},
		getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
			this.$server
				.getAPPInfo({
					promotionChannel: from,
					promotionName: filename,
				})
				.then((res) => {
					if (res.code === 200) {
						this.partners = res.data.partners
						this.updateTime = res.data.updateTime
						this.clicks = res.data.clicks
						this.pageIntSuccess = true
						if (isFromQuery) {
							this.apk = res.data.apk
							this.updateAPK()
						}
					}
				})
				.catch((err) => {
					console.error('Error fetching APK:', err)
				})
		},
		getOfficialApk() {
			this.$server
				.getOfficialApk({
					domain: 'gbpro.download',
					appName: 'GB_gbpro.download',
				})
				.then((res) => {
					if (res.code === 200) {
						this.apk = res.data.apk
						this.updateAPK()
						console.log('APK fetched successfully')
					}
				})
				.catch((err) => {
					console.error('Error fetching official APK:', err)
				})
		},
	},
}
</script>
