<template>
	<section class="domain_content">
		<h1>Guide to Change Themes in GB WhatsApp</h1>
		<p>GB WhatsApp has gained a large user base, thanks in part to its impressive customization features. Among these, the ability to set themes is particularly popular. This feature attracts many users who seek a personalized look and feel for WhatsApp.</p>
		<p>As a MOD version based on WhatsApp, GB WhatsApp shares the same underlying system as the official WhatsApp. This compatibility makes it easy for users to switch to GB WhatsApp.</p>
		<p>GB WhatsApp collects users' advice and developes a wide range of customization options.</p>
		<p>
			<img
				class="wi"
				alt="img"
				src="@/assets/blog5.webp" />
		</p>
		<h2>How to Change the Theme in GB WhatsApp</h2>
		<p>Changing the theme in GB WhatsApp is popular among the young. Follow these simple steps to give your app a fresh new look:</p>
		<ol>
			<li>
				<strong>Install and login account in GB WhatsApp:</strong>
				<p>If you haven't install GB WhatsApp, you can safely download GB WhatsApp APK in our site:</p>
				<div
					exp-data="show_gbhome_download"
					class="big-download-btn top-download fix has-exp"
					data-exposure="1"
					@click="download(apk)">
					<img
						:src="downloadicon"
						alt="download"
						class="download-img" />
					<div class="download-name">GB WhatsApp Download</div>
				</div>
				<p>If you already login to your account, go to the second step.</p>
			</li>

			<li>
				<strong>Access the theme Settings:</strong>
				<ul>
					<li>Tap on the three vertical dots in the top right corner to open the menu.</li>
					<li>Select "GB Settings" from the dropdown menu.</li>
					<li>In the Settings menu, tap on "Themes > Download themes".</li>
				</ul>
			</li>
			<li>
				<strong>Browse and Apply Themes:</strong>
				<ul>
					<li><span>croll through the 4000+ themes or use the search bar to find a specific theme.</span></li>
					<li>
						<span>Once you find a theme you like, tap on "Install".</span>
						<p>
							<img
								class="wi"
								alt="img"
								src="@/assets/blog52.webp" />
						</p>
					</li>
				</ul>
			</li>
			<li>
				<strong>Restart GBWhatsApp:</strong>
				<p>After applying the theme, you may need to restart GBWhatsApp to see the changes take effect.</p>
      </li>
		</ol>
    <h2>
      Conclusion
    </h2>
    <p>
      Setting a theme is a simple way to personalize GB WhatsApp. By following the steps outlined above, users ca easily find out a theme they like in a large number of themes provided by GB WhatsApp. However, it's crucial to ensure you download GB WhatsApp from a secure and trusted site to protect your device from potential security risks. Our site will keep updating<a @click="download(apk)" href="javascript:void(0)">the latest version of GB WhatsApp.</a>  
    </p>
		<!-- 下载 -->
	</section>
</template>

<script>
import '@/css/default.scss'
import { mapGetters } from 'vuex'
const title = 'Change Themes in GB WhatsApp: A Guide for Users'
const description = 'GB WhatsApp provide users with over 4000 themes. Users can choose one they like and install it . As for how to change the themes of GB WhatsApp, we put some steps to help you change it.  '
export default {
	data() {
		return {
			from: null,
			filename: null,
			partners: null,
			updateTime: null,
			clicks: null,
			pageIntSuccess: null,
			apk: null,
		}
	},
	metaInfo: {
    title,
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        name: 'title',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
    ],
  },
	computed: {
		...mapGetters(['showDebug', 'host', 'downloadicon']),
	},
	created() {
		this.initParams()
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		download(apk) {
			this.$global.download(apk)
		},
		initParams() {
			this.from = this.$global.GetQueryString('from')
			this.filename = this.$global.GetQueryString('filename')
		},
		fetchData() {
			if (this.from) {
				this.getAPK(true, this.from, this.filename)
			} else {
				this.getAPK()
				this.getOfficialApk()
			}
		},
		updateAPK() {
			this.$emit('update-apk', this.apk)
		},
		getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
			this.$server
				.getAPPInfo({
					promotionChannel: from,
					promotionName: filename,
				})
				.then((res) => {
					if (res.code === 200) {
						this.partners = res.data.partners
						this.updateTime = res.data.updateTime
						this.clicks = res.data.clicks
						this.pageIntSuccess = true
						if (isFromQuery) {
							this.apk = res.data.apk
							this.updateAPK()
						}
					}
				})
				.catch((err) => {
					console.error('Error fetching APK:', err)
				})
		},
		getOfficialApk() {
			this.$server
				.getOfficialApk({
					domain: 'gbpro.download',
					appName: 'GB_gbpro.download',
				})
				.then((res) => {
					if (res.code === 200) {
						this.apk = res.data.apk
						this.updateAPK()
						console.log('APK fetched successfully')
					}
				})
				.catch((err) => {
					console.error('Error fetching official APK:', err)
				})
		},
	},
}
</script>
