<template>
<section class="domain_content">
    <h1>{{ doc.blogList[language][1].title}}</h1>

    <p>
        GB WhatsApp has gained a large user base, thanks in part to its impressive customization features. Among these, the ability to set themes is particularly popular. This feature attracts many users who seek a personalized look and feel for WhatsApp.
    </p>

    <p>
        As a MOD version built on WhatsApp's original framework, GB WhatsApp retains full compatibility with the official app.
    </p>
    <p>
        GB WhatsApp actively listens to user feedback, continuously adding a variety of customization features to enhance the experience.
    </p>
    <p>
        <img class="wi" alt="change WhatsApp themes" src="@/assets/blog10-2.webp" />
    </p>

    <h2>
        How to Change the Theme in GB WhatsApp
    </h2>

    <h6>1. Install and login account in GB WhatsApp:</h6>
    <ul>
        <li>If you haven't installed GB WhatsApp yet, visit our trusted site to safely download the latest APK version.</li>
        <li>If you're already logged in, you can skip ahead to the next step.</li>
    </ul>

    <h6>2. Access the theme Settings:</h6>
    <ul>
        <li>Open GB WhatsApp and tap the three vertical dots in the top-right corner to access the menu.</li>
        <li>From the dropdown menu, select GB Settings.</li>
        <li>Once in the settings menu, tap on <strong>Themes > Download Themes</strong> to access the theme options.</li>
    </ul>

    <h6>3. Browse and Apply Themes:</h6>
    <ul>
        <li>Explore the extensive library of over 4,000 themes or use the search bar to find something specific.</li>
        <li>Once you've chosen a theme you like, tap <strong>Install</strong> to apply it.</li>
    </ul>

    <p class="pic-fit landscape">
        <img src="@/assets/themes.webp" alt="Select themes" />
    </p>

    <h6>4. Restart GB WhatsApp:</h6>
    <p>After applying the theme, you may need to restart GB WhatsApp to see the changes take effect.</p>

    <h2>
        Conclusion
    </h2>
    <p>
        Setting a theme is a simple way to personalize GB WhatsApp. By following the steps outlined above, users ca easily find out a theme they like in a large number of themes provided by GB WhatsApp.
    </p>
    <p>
        However, to avoid security risks, it's important to download GB WhatsApp only from trusted and secure sources. Our website ensures you always have access to the latest, safest version of the app.
    </p>

    <RecentPost :doc="doc" :language="language"/>

</section>
</template>

<script>
import '@/css/default.scss';
import doc from '@/documents/blog10.js';
import RecentPost from '@/components/RecentPost.vue';
import {
    mapGetters
} from 'vuex';
const title = 'Change Themes in GB WhatsApp: A Guide for Users'
const description = 'GB WhatsApp provide users with over 4000 themes. Users can choose one they like and install it . As for how to change the themes of GB WhatsApp, we put some steps to help you change it.'
export default {
    data() {
        return {
            doc: doc,
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            language: 'en',
        };
    },
    metaInfo: {
        title,
        meta: [{
                name: 'description',
                content: description,
            },
            {
                name: 'title',
                content: title,
            },
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
        "link": [{
            "rel": "canonical",
            "href": "https://waproapk.com/change-themes-gbwhatsapp/"
        }]
    },
    components: {
        RecentPost,
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
