<template>
<section class="domain_content">
    <h1>{{ doc.blogList[language][0].title}}</h1>

    <h2>
        How to Download and Install GB WhatsApp Pro on Mac?
    </h2>

    <p>
        If you are alread a GB WhatsApp Pro user and would like to download GB WhatsApp Pro on Mac. You might already know the possibility is zero. There is no Mac version of GB WhatsApp Pro.
    </p>

    <p>
        However, some users of this kind of mod app find another way to solve this problem. That is to install GB WhatsApp Pro on Mac via BlueStacks.
    </p>
    <p>
        Now, follow our guide to download and install GB WhatsApp Pro on Mac or Windows(7,8,10,11) step by step.
    </p>
    <p>
        <img class="wi" alt="GB WhatsApp pro for mac" src="@/assets/blog10-1.webp" />
    </p>

    <h2>
        Steps to install GB WhatsApp Pro on Mac
    </h2>
    <h6>
        Install BlueStacks on Mac
    </h6>
    <ol>
        <li>
            Firstly, you should download the Android emulator BlueStacks. Go to the official website: <a href="https://www.bluestacks.com/" target="_blank" ref="nofollow">https://www.bluestacks.com/</a>
        </li>
        <li>
            Follow the on-screen prompts and install BlueStacks.
        </li>
    </ol>
    <h6>
        Install GBWhatsApp Pro on Mac
    </h6>
    <ol>
        <li>
            Download the <a href="\" target="_blank">latest version of GBWhatsApp Pro</a> from a trusted site.
        </li>
        <li>
            Click on "Install APK on BlueStacks". For more details, you can read the user guide of BlueStacks.
        </li>
        <li>
            Now, you can launch GB WhatsApp and verifiy your phone number.
        </li>
    </ol>

    <h2>
        Main Features of GB WhatsApp Pro
    </h2>
    <p>
        Remember the features of GB WhatsApp Pro for Android? Now you can use them on Mac:
    </p>
    <ul>
        <li>Hide online status</li>
        <li>Share high resolution images</li>
        <li>Share large files</li>
        <li>Read deleted messages</li>
        <li>Download statuses</li>
        <li>Change themes</li>
        <li>DND mode</li>
        <li>Change wallpaper</li>
        <li>Hide Blue Ticks</li>
    </ul>

    <RecentPost :doc="doc" :language="language"/>
</section>
</template>

<script>
import '@/css/default.scss';
import doc from '@/documents/blog10.js';
import RecentPost from '@/components/RecentPost.vue';
import {
    mapGetters
} from 'vuex';
const title = 'GB WhatsApp Pro for Mac: Steps to Download and Install'
const description = 'How to use GB WhatsApp Pro for Mac? Here we list the simple but effective methods to help users to download and install GB WhatsApp Pro on Mac.'
export default {
    data() {
        return {
            doc: doc,
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            language: 'en'
        };
    },
    metaInfo: {
        title,
        meta: [{
                name: 'description',
                content: description,
            },
            {
                name: 'title',
                content: title,
            },
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
        "link": [{
            "rel": "canonical",
            "href": "https://waproapk.com/download-gbwhatsapp-on-mac/"
        }]
    },
    components: {
        RecentPost,
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>