export default {
    blogList: [
        {
            title: 'Download the Old Version of GB WhatsApp APK',
            desc: 'GB WhatsApp keeps updated for many years, there are many versions in a year. We collect some stable pld versions in this post. Be note that the download link provided in this blog is for old versions of GB WhatsApp APK. ',
            url: 'download-gbwhatsapp-old-version-apk',
            img: require('@/assets/blog12-1.webp'),
            alt: 'GB WhatsApp Old Version'
        },
        {
            title: 'What is WhatsApp Plus and How to Use It without Being Banned?',
            desc: 'Most of you may know WhatsApp Messenger which gains billions of users for staying in touch with friends and family. It\'s a free message app with secure, reliable messaging and calling. Users can download it from Google Play Store, App Store and WhatsApp official website. Both mobile and desktop versions are available. However, some users are waiting for more convenient features. Then, the modified versions came out.',
            url: 'download-whatsapp-plus-apk-safely',
            img: require('@/assets/blog12-2.webp'),
            alt: 'GB WhatsApp Plus'
        },
        {
            title: 'Safe Download GB WhatsApp APK and Avoid Being Banned',
            desc:'Developed by third-party developers as a modification version of WhatsApp, GB WhatsApp provides users with additional features not available in the standard WhatsApp. These enhancements provide greater control over messaging, allowing users for a more personalized and versatile communication experience with friends and family.',
            url: 'use-gbwhatsapp-without-being-banned',
            img: require('@/assets/blog12-3.webp'),
            alt: 'GB WhatsApp'
        },
        {
            title: 'Comparison of WhatsApp & GB WhatsApp',
            desc: 'WhatsApp has captivated over 2 billion active users worldwide with its user-friendly interface, reliability, and versatile features, making it a go-to platform for personal and professional communication. ',
            url: 'difference-of-gbwhatsapp-whatsapp',
            img: require('@/assets/blog12-4.webp'),
            alt: 'GB WhatsApp vs WhatsApp'
        },
        {
            title: 'Explore JTWhatsApp With More Custom Features',
            desc: 'JTWhatsApp JiMODs is a popular alternative to the official WhatsApp, offering a range of enhanced features that elevate the messaging experience. Created by Jimtechs, this modified version caters to users looking for more control, customization, and privacy options that aren\'t available in the original app.',
            url: 'jtwhatsapp-introduction',
            img: require('@/assets/blog12-5.webp'),
            alt: 'JT WhatsApp'
        }
    ],
    urlMap: {
        0: 'download-gbwhatsapp-old-version-apk',
        1: 'download-whatsapp-plus-apk-safely',
        2: 'use-gbwhatsapp-without-being-banned',
        3: 'difference-of-gbwhatsapp-whatsapp',
        4: 'jtwhatsapp-introduction'
    }
}