<template>
<section class="domain_content">
    <h1 class="title">
        اكتشف الميزات الإضافية لتطبيق GB WhatsApp 2024
    </h1>

    <p class="pic-fit">
        <img src="@/assets/domain15-1.webp" alt="واتساب بلس معتمد" title="اكتشف الميزات الإضافية لتطبيق GB WhatsApp 2024 1">
    </p>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="تنزيل" class="download-img" />
        <div class="download-name">تنزيل GB WhatsApp</div>
    </div>

    <p>
        في الحياة الحديثة، لا يستطيع أحد العيش بدون هاتف محمول وتطبيق مراسلة. <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.whatsapp.com/">WhatsApp</a> هو أحد تطبيقات المراسلة الفورية التي تساعدك على التواصل مع الناس. حتى عام 2024، حصل WhatsApp على مليارات المستخدمين في جميع أنحاء العالم. بعد ذلك، تم تطوير بعض التطبيقات المعدلة لـ WhatsApp. يبحث الناس عن ميزات إضافية وهناك من يلبي احتياجاتهم.
    </p>

    <p>
        GB WhatsApp هو أحد الإصدارات المعدلة الشهيرة من WhatsApp الأصلي. إنه ليس شائعًا جدًا ولكنه يقلل من مخاطر الحظر مقارنة بالتطبيقات المعدلة الأخرى.
    </p>

    <TOC :tocList="toc" />

    <h2 id="tbc_1">
        {{toc[0]}}
    </h2>

    <p>
        تم تطوير GB WhatsApp بالكامل بناءً على WhatsApp الرسمي. يمكن للمستخدمين استخدامه كإصدار جديد من WhatsApp للتواصل مع الآخرين. يمكن للمستخدمين الذين يستخدمون GB WhatsApp إرسال الرسائل إلى مستخدمي WhatsApp. يشتركون في نفس النظام.
    </p>

    <p>
        لذا، إرسال الرسائل النصية، إجراء مكالمات الفيديو، إرسال الرسائل الصوتية، إنشاء أو الانضمام إلى المجموعات كلها ميزات متاحة في GB WhatsApp. أما بالنسبة للميزات الإضافية، فقد قسمناها إلى ثلاثة أجزاء. يرجى المتابعة.
    </p>

    <div class="features">
        <p class="pic-fit">
            <img src="@/assets/domain15-2.webp" alt="ميزات التخصيص" title="اكتشف الميزات الإضافية لتطبيق GB WhatsApp 2024 2">
        </p>

        <p>
            <strong>الثيمات</strong> <br>
            واحدة من الميزات الشهيرة في GB WhatsApp هي تخصيص الثيمات. هذا يعني أن المستخدمين يمكنهم تغيير الثيمات من متجر الثيمات الذي يحتوي على أكثر من 4000 ثيم. يمكن للمستخدمين تصميم الألوان، الخط، وخلفية الدردشة في GB WhatsApp.
        </p>

        <p>
            <strong>أيقونة علامات القراءة الزرقاء</strong> <br>
            من المدهش حقًا أن العلامة التقليدية لقراءة الرسائل يمكن تغييرها في GB WhatsApp. يقدم المطورون مجموعة من الأيقونات لتختار منها لاستبدال العلامات الزرقاء.
        </p>

        <p>
            <strong>تمييز كغير مقروء</strong> <br>
            يمكن للمستخدمين تمييز أي رسائل على أنها غير مقروءة. إنه أمر مريح للمستخدمين لقراءتها لاحقًا عندما لا يكون هناك وقت لقراءتها على الفور.
        </p>

        <p>
            <strong>تصفية الرسائل</strong> <br>
            يضيف GB WhatsApp ميزة تصفية الرسائل لتصفية الرسائل النصية أو الصور بسهولة.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-3.webp" alt="ميزات الخصوصية" title="اكتشف الميزات الإضافية لتطبيق GB WhatsApp 2024 3">
        </p>

        <p>
            <strong>وضع الشبح</strong> <br>
            يتم إخفاؤه في إعدادات GB WhatsApp. انتقل إلى خيار الخصوصية وقم بتمكين هذا الخيار. ستظهر لك أيقونة الشبح على شريط الواجهة الرئيسية. ثم يمكنك تشغيل أو إيقاف الوضع بسهولة.
        </p>

        <p>
            <strong>حظر المكالمات الواردة</strong> <br>
            كما يشير الاسم، يمكن حظر المكالمات الواردة طالما قمت بتعيين الحدود.
        </p>

        <p>
            <strong>إخفاء العلامات الزرقاء المزدوجة</strong> <br>
            هل تساءلت يومًا إذا كان بإمكانك إخفاء العلامات المزدوجة؟ في GB WhatsApp، يمكنك تحقيق ذلك. يمكن إخفاء العلامات الزرقاء ويمكنك قراءة الرسائل بحرية.
        </p>

        <p>
            <strong>مقاومة حذف الرسائل</strong> <br>
            يحتوي GB WhatsApp على ميزة لمقاومة حذف الرسائل. يمكنك رؤية الرسائل المحذوفة أو المستعادة في صندوق الدردشة الخاص بك. ويمكن أيضًا البحث عن الرسائل المحذوفة في سجل الدردشة.
        </p>

        <p>
            <strong>إخفاء تعقب المشاهدات</strong> <br>
            يمكن للمستخدمين رؤية حالات جهات الاتصال دون إشعارهم. يمكن إخفاء تعقب المشاهدات.
        </p>

        <p>
            <strong>إخفاء حالتك</strong> <br>
            يمكن إخفاء حالة "الكتابة..." أو "تسجيل الصوت..." عند جهات الاتصال أو المجموعات أو الآخرين التي تظهر على الشريط العلوي لصندوق الدردشة في GB WhatsApp.
        </p>

        <p>
            <strong>تعطيل إعادة التوجيه</strong> <br>
            عند إعادة توجيه رسالة أو صورة من دردشة أخرى، يمكنك إزالة علامة إعادة التوجيه.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-4.webp" alt="ميزات أخرى" title="اكتشف الميزات الإضافية لتطبيق GB WhatsApp 2024 4">
        </p>
        <ul>
            <li>قفل التطبيق للأمان</li>
            <li>إرسال الصور والفيديوهات حتى 700 ميجابايت بدون ضغط</li>
            <li>إرسال الرسائل مباشرة إلى أرقام الهواتف</li>
        </ul>
    </div>

    <h2 id="tbc_2">
        {{toc[1]}}
    </h2>
    <table>
        <tr>
            <th>الميزات</th>
            <th>واتساب بلس</th>
            <th>واتساب</th>
        </tr>
        <tr>
            <td><strong>وضع الشبح</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>تحديد الرسائل كغير مقروءة</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>إخفاء الحالة</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>تغيير العلامات الزرقاء</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>إخفاء العلامات الزرقاء</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>حظر مكالمات الفيديو</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>وضع عدم الإزعاج</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>قراءة الرسائل المحذوفة</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>تغيير الثيمات</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>تغيير فقاعات الدردشة</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>تأثيرات</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>تحديد كغير مقروء</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>جدولة الرسائل</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>حجم الصور</strong></td>
            <td>100 ميجابايت</td>
            <td>100 ميجابايت</td>
        </tr>
        <tr>
            <td><strong>جودة ملفات الوسائط</strong></td>
            <td>بدون ضغط</td>
            <td>ضغط</td>
        </tr>
        <tr>
            <td><strong>تصفية الرسائل</strong></td>
            <td>✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td><strong>مركز المساعدة</strong></td>
            <td>❌</td>
            <td>✔️</td>
        </tr>
    </table>

    <h2 id="tbc_3">
        {{toc[2]}}
    </h2>

    <ul>
        <li><strong>قبل تنزيل ملفات APK:</strong><br>
            أي ملفات APK من مصادر خارجية سيتم اكتشافها كمصدر غير معروف بواسطة نظام Android أثناء التثبيت. لذلك، يجب على المستخدمين تمكين <strong>السماح من هذا المصدر</strong>. يمكن العثور عليه أيضًا تحت "الأذونات الخاصة" على هواتف Android.
            <p class="pic-fit landscape">
                <img src="@/assets/domain15-5.webp" alt="تثبيت التطبيقات غير المعروفة" title="افتح الميزات الإضافية في واتساب بلس 2024 5">
            </p>
        </li>
        <li><strong>تثبيت واتساب بلس:</strong><br>
            بعد تأكيد الإذن لتشغيل التثبيت، يمكن للمستخدمين اتباع الدليل لإنهاء العملية. تأكد من أنك على دراية بالمخاطر وأعطِ الإذن. بعد ذلك يمكنك استخدام التطبيق.
        </li>
    </ul>

    <h2 id="tbc_4">
        {{toc[3]}}
    </h2>
    <p>
        لنقل البيانات من واتساب إلى واتساب بلس، يجب عمل نسخة احتياطية أولاً. انتقل إلى الخصوصية > الدردشة > سجل الدردشة، ثم قم بتعيين تكرار النسخ الاحتياطي إلى يوميًا. عادةً، سيتم تحميل البيانات إلى Google، ولكن واتساب بلس لا يمكنه الوصول إلى بيانات Google لأنه ليس رسميًا.
    </p>

    <p>
        اتبع الخطوات أدناه لإنهاء النقل:
        <ul>
            <li><strong>الخطوة 1: </strong>تعطيل النسخ الاحتياطي إلى حساب Google. لا يوجد زر لهذا. ولكن يمكنك محاولة قطع الاتصال بشبكة WiFi أو الخلوي.</li>
            <li><strong>الخطوة 2: </strong>انتقل إلى <strong>مدير الملفات</strong> في هواتف Android، واذهب إلى <strong>Android > Media > com.whatsapp > WhatsApp</strong>. سيتم حفظ البيانات هناك. يمكنك التحقق إذا كان وقت التحديث هو اليوم الذي قمت فيه بالنسخ الاحتياطي.
                <p class="pic-fit landscape">
                    <img src="@/assets/domain15-6.webp" alt="المجلد" title="افتح الميزات الإضافية في واتساب بلس 2024 6">
                </p>
            </li>
            <li><strong>الخطوة 3: </strong>نسخ <strong>النسخ الاحتياطية</strong> و<strong>قاعدة البيانات</strong> و<strong>الوسائط</strong>، ثم لصقها في مجلد آخر. تذكر مكان المجلد. سنحتاجه في الخطوات التالية.</li>
            <li><strong>الخطوة 4: </strong>إلغاء تثبيت واتساب. إذا كنت قلقًا، يمكنك اختيار عدم مسح ذاكرة التخزين المؤقت.</li>
            <li><strong>الخطوة 5: </strong>إنشاء مجلد باسم <strong>com.gbwhatsapp</strong> تحت <strong>الوسائط</strong>. ثم إنشاء مجلد <strong>GB WhatsApp</strong> تحته. بعد ذلك انقل <strong>النسخ الاحتياطية</strong> و<strong>قاعدة البيانات</strong> و<strong>الوسائط</strong> إلى هناك.</li>
            <li><strong>الخطوة 6: </strong>عد إلى الواجهة الرئيسية وشغّل واتساب بلس، ويمكنك استعادة البيانات هذه المرة.</li>
        </ul>
    </p>

    <h2 id="tbc_5">
        {{toc[4]}}
    </h2>
    <ol>
        <li>ترقية إلى أحدث إصدار من داخل واتساب بلس: تحقق من الإصدار الحالي في معلومات التطبيق، وإذا كان هناك إصدار جديد، سيتم إشعارك بالتحديث.</li>
        <li>تنزيل أحدث إصدار من الموقع: قم بزيارة هذا الموقع الموثوق لتنزيل أحدث ملف APK.</li>
    </ol>
    <h4 style="text-align:center;">
        معلومات التطبيق
    </h4>
    <table>
        <tr>
            <th>الاسم</th>
            <th>واتساب بلس</th>
        </tr>
        <tr>
            <td>الحجم</td>
            <td>77 ميجابايت</td>
        </tr>
        <tr>
            <td>الإصدار</td>
            <td>18.10</td>
        </tr>
        <tr>
            <td>التحديث</td>
            <td>منذ يوم</td>
        </tr>
        <tr>
            <td>الترخيص</td>
            <td>مجاني</td>
        </tr>
    </table>
    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="تنزيل" class="download-img" />
        <div class="download-name">تنزيل واتساب بلس 18.10</div>
    </div>
    <h2 id="tbc_6">
        {{toc[5]}}
    </h2>
    <p>
        <strong>حسابات GB WhatsApp:</strong> بما أن WhatsApp الرسمي وGB WhatsApp يشتركان في نفس الحساب، وقد عزز فريق WhatsApp الرسمي آلية مكافحة التعديلات. قد يواجه مستخدمو GB WhatsApp مخاطر حظر الحسابات. هذا أمر لا مفر منه، لذا يختار عدد كبير من المستخدمين استخدام رقم هاتف ثانوي لتسجيل الدخول إلى تطبيق GB WhatsApp.
    </p>
    <p>
        <strong>تسرب المعلومات الشخصية:</strong> تختلف المخاوف من شخص لآخر. بعضهم قاموا بتثبيت أكثر من إصدار معدّل أو مكسور. بعضهم الآخر حذر جداً بشأن المعلومات الشخصية. لا يوجد دليل مباشر يوضح مسألة سرقة المعلومات.
    </p>

    <h2 id="tbc_7">
        {{toc[6]}}
    </h2>
    <FAQ :faqs="faqs" />
</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import TOC from '@/components/TOC.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    ...{
        "metaInfo": {
            "title": "Safe Download GB WhatsApp 2024 to Unlock Extra Features",
            "meta": [{
                "name": "description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }, {
                "name": "title",
                "content": "GBWhatsApp"
            },
             {
                "property": "og:title",
                "content": "GBWhatsApp"
            }, {
                "property": "og:description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapps.homes/ar/"
            }]
        }
    },
    components: {
        TOC,
        FAQ
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            language: 'ar',
            toc: [
                'لماذا يختار الناس استخدام GB WhatsApp؟',
                'مقارنة بين GB WhatsApp وWhatsApp',
                'تنزيل وتثبيت GB WhatsApp',
                'نقل البيانات من WhatsApp إلى GB WhatsApp',
                'طرق تحديث ملف GB WhatsApp Apk',
                'مشاكل الأمان: هل GB WhatsApp آمن؟',
                'الأسئلة الشائعة'
            ],
            faqs: [{
                    question: 'ما الجديد في أحدث إصدار من GB WhatsApp؟',
                    answer: 'قامت أحدث نسخة من GB WhatsApp بإصلاح بعض الأخطاء وتعزيز حماية مكافحة الحظر.',
                },
                {
                    question: 'هل يمكنني استخدام إصدار قديم من GB WhatsApp؟',
                    answer: 'إذا كنت تستخدم إصداراً قديماً، يمكنك الاحتفاظ به. ولكن لا تنسَ عمل نسخة احتياطية للبيانات بانتظام. إذا كان قديمًا جدًا، يمكنك ترقية إلى أحدث إصدار.',
                },
                {
                    question: 'GB WhatsApp لجهاز iPhone',
                    answer: 'لا يدعم GB WhatsApp نظام iOS. لا يمكن لمستخدمي iPhone استخدامه. إذا كنت مهتماً، يمكنك البحث عن كيفية تثبيت نظام Android على YouTube.',
                },
                {
                    question: 'ما هو Blue WhatsApp Plus؟',
                    answer: 'طورت فريق BlueMods تطبيق Blue WhatsApp Plus بميزات أكثر من WhatsApp الرسمي. إنه إصدار MOD آخر. يمكنك العثور على نفس الميزات فيه ولكن مع اختلافات قليلة في الشعار والواجهة.',
                },
            ]
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
