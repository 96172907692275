// src/router/index.js
import Vue from 'vue'
import Router from 'vue-router'
import Privacy from '@/pages/privacy'
import BlogIndex from '@/pages/blogIndex'
Vue.use(Router)

const domainMappings = {
	'gbwhatsapps.chat': 'domain-1',
	'gbwhatsapk.org': 'domain-2',
	'gbwhatsapk.net': 'domain-3',
	'gbapks.com.in': 'domain-4',
	'gbapks.com': 'domain-5',
	'gbapks.org.in': 'domain-6',
	'gbapks.click': 'domain-7',
	'waplusapk.click': 'domain-8',
	'waplusapk.download': 'domain-9',
	'waproapk.com': 'domain-10',
	'waproapk.net': 'domain-11',
	'whatsapppro.org': 'domain-12',
	'whatsappapk.download': 'domain-13',
	'gbwhatsapk.chat': 'domain-14',
	'gbapps.homes': 'domain-15',
	'test': 'test',
	localhost: 'domain-7',
}
const blogMappings = {
	// 'gbapks.com': 'blog-1',
	// 'gbwhatsapk.net': 'blog-2',
	// localhost: 'blog-1',
	'gb-whatsapp-download': 'blog-1',
	// 'gbwhatsapp-pro-download'
}

function resolveComponent(componentPath) {
	if (process.server) {
		return require(`@/components/home/${componentPath}.vue`).default
	} else {
		return (resolve) => require([`@/components/home/${componentPath}.vue`], resolve)
		// return () => import(`@/components/home/${componentPath}.vue`)
	}
}

function DynamicDomainComponent(baseURL) {
	console.log(`home router.js打印的url地址 用于判断渲染文件>>${baseURL}`)
	const key = Object.keys(domainMappings).find((domain) => baseURL.includes(domain))
	const component = domainMappings[key] || 'domain-12'
	return resolveComponent(component)
}

function resolveBlogComponent(componentPath) {
	if (process.server) {
		return require(`@/components/blogs/${componentPath}.vue`).default
	} else {
		// return () => import(`@/components/blogs/${componentPath}.vue`)
		return (resolve) => require([`@/components/blogs/${componentPath}.vue`], resolve)
	}
}

function DynamicDomainComponentBlog(baseURL) {
	console.log(`blog router.js打印的url地址 用于判断渲染文件>>${baseURL}`)
	const key = Object.keys(blogMappings).find((domain) => baseURL.includes(domain))
	const component = blogMappings[key] || 'blog-1'
	return resolveBlogComponent(component)
}

export default function createRouter(baseURL = '') {
	const baseRoutes = [
		{
			path: '/',
			name: 'home',
			component: DynamicDomainComponent(baseURL),
		},
	]
	if (baseURL.indexOf('gbapks.com') >= 0 && baseURL.indexOf('gbapks.com.in') < 0) {
		baseRoutes.push(
			{
				path: '/blogs',
				name: 'blogs',
				component: BlogIndex,
			},
			{
				path: '/privacy',
				name: 'privacy',
				component: Privacy,
			},
			{
				path: '/gb-whatsapp-download',
				component: () => import('@/components/blogs/blog-1.vue'),
			},
			{
				path: '/gbwhatsapp-pro-download',
				component: () => import('@/components/blogs/blog-2.vue'),
			},
			{
				path: '/use-gbwhatsapp-without-getting-ban',
				component: () => import('@/components/blogs/blog-3.vue'),
			},
			{
				path: '/difference-between-gbwhatsapp-whatsapp',
				component: () => import('@/components/blogs/blog-4.vue'),
			},
			{
				path: '/change-gbwhatsapp-themes',
				component: () => import('@/components/blogs/blog-5.vue'),
			},
			{
				path: '/gbwhatsapp-web-desktop',
				component: () => import('@/components/blogs/blog-6.vue'),
			},
			{
				path: '/5-messaging-apps',
				component: () => import('@/components/blogs/blog-7.vue'),
			},
		)
	}
	else if (baseURL.indexOf('whatsapppro.org') >= 0) {
		baseRoutes.push(
			{
				path: '/id',
				component: () => import('@/components/home/domain-12id.vue'),
			},
			{
				path: '/pt',
				component: () => import('@/components/home/domain-12pt.vue'),
			},
			{
				path: '/ar',
				component: () => import('@/components/home/domain-12ar.vue'),
			},
			{
				path: '/de',
				component: () => import('@/components/home/domain-12de.vue'),
			},
			{
				path: '/es',
				component: () => import('@/components/home/domain-12es.vue'),
			},
			{
				path: '/hi',
				component: () => import('@/components/home/domain-12hi.vue'),
			},
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-12.vue'),
			},
			{
				path: '/disclaimer',
				component: () => import('@/pages/disclaimer-12.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-12.vue'),
			},
			{
				path: '/gb-whatsapp-for-pc',
				component: () => import('@/pages/pc-12.vue'),
			},
			{
				path: '/gb-whatsapp-old-versions',
				component: () => import('@/pages/old-12.vue'),
			},
			{
				path: '/blogs',
				component: () => import('@/pages/blogIndex-12.vue'),
			},
			{
				path: '/download-gbwhatsapp-old-version-apk',
				component: () => import('@/components/blogs/blog12-1.vue'),
			},
			{
				path: '/download-whatsapp-plus-apk-safely',
				component: () => import('@/components/blogs/blog12-2.vue'),
			},
			{
				path: '/use-gbwhatsapp-without-being-banned',
				component: () => import('@/components/blogs/blog12-3.vue'),
			},
			{
				path: '/difference-of-gbwhatsapp-whatsapp',
				component: () => import('@/components/blogs/blog12-4.vue'),
			},
			{
				path: '/jtwhatsapp-introduction',
				component: () => import('@/components/blogs/blog12-5.vue'),
			},
			{
				path: '/download-gb-whatsapp-new',
				component: () => import('@/pages/download-12.vue'),
			},
			{
				path: '/more-mods',
				component: () => import('@/pages/more-mods-12.vue'),
			},
			{
				path: '/download-gold-whatsapp',
				component: () => import('@/pages/gold-12.vue'),
			},
			{
				path: '/download-gb-whatsapp-pro',
				component: () => import('@/pages/pro-12.vue'),
			}
		)
	}
	else if (baseURL.indexOf('waproapk.net') >= 0) {
		baseRoutes.push(
			{
				path: '/id',
				component: () => import('@/components/home/domain-11id.vue'),
			},
			{
				path: '/pt',
				component: () => import('@/components/home/domain-11pt.vue'),
			},
			{
				path: '/ar',
				component: () => import('@/components/home/domain-11ar.vue'),
			},
			{
				path: '/de',
				component: () => import('@/components/home/domain-11de.vue'),
			},
			{
				path: '/es',
				component: () => import('@/components/home/domain-11es.vue'),
			},
			{
				path: '/hi',
				component: () => import('@/components/home/domain-11hi.vue'),
			},
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-11.vue'),
			},
			{
				path: '/disclaimer',
				component: () => import('@/pages/disclaimer-11.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-11.vue'),
			},
			{
				path: '/download-gbwhatsapp-apk',
				component: () => import('@/pages/download-11.vue'),
			},
			{
				path: '/gbwhatsapp-ios',
				component: () => import('@/pages/iphone-11.vue'),
			},
		)
	}
	else if (baseURL.indexOf('gbwhatsapk.net') >= 0) {
		baseRoutes.push(
			{
				path: '/id',
				component: () => import('@/components/home/domain-3id.vue'),
			},
			{
				path: '/pt',
				component: () => import('@/components/home/domain-3pt.vue'),
			},
			{
				path: '/ar',
				component: () => import('@/components/home/domain-3ar.vue'),
			},
			{
				path: '/hi',
				component: () => import('@/components/home/domain-3hi.vue'),
			},
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-3.vue'),
			},
			{
				path: '/disclaimer',
				component: () => import('@/pages/disclaimer-3.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-3.vue'),
			},
			{
				path: '/more-mods',
				component: () => import('@/pages/more-mods-3.vue'),
			},
			{
				path: '/og-whatsapp-apk',
				component: () => import('@/pages/og-3.vue'),
			}
		)
	}
	else if (baseURL.indexOf('gbapps.homes') >= 0) {
		baseRoutes.push(
			{
				path: '/id',
				component: () => import('@/components/home/domain-15id.vue'),
			},
			{
				path: '/pt',
				component: () => import('@/components/home/domain-15pt.vue'),
			},
			{
				path: '/ar',
				component: () => import('@/components/home/domain-15ar.vue'),
			},
			{
				path: '/de',
				component: () => import('@/components/home/domain-15de.vue'),
			},
			{
				path: '/es',
				component: () => import('@/components/home/domain-15es.vue'),
			},
			{
				path: '/hi',
				component: () => import('@/components/home/domain-15hi.vue'),
			}
		)
	}
	else if (baseURL.indexOf('waplusapk.click') >= 0) {
		baseRoutes.push(
			{
				path: '/fm-whatsapp-apk',
				component: () => import('@/pages/fm-8.vue'),
			},
			{
				path: '/pt',
				component: () => import('@/components/home/domain-8pt.vue'),
			},

			{
				path: '/es',
				component: () => import('@/components/home/domain-8es.vue'),
			},
		)
	}
	else if (baseURL.indexOf('gbwhatsapk.org') >= 0) {
		baseRoutes.push(
			{
				path: '/download-gbwhatsapp',
				component: () => import('@/pages/download-2.vue'),
			},
			{
				path: '/gb-whatsapp-pro',
				component: () => import('@/pages/pro-2.vue'),
			},
		)
	}
	else if (baseURL.indexOf('gbapks.org.in') >= 0) {
		baseRoutes.push(
			{
				path: '/gbwhatsapp-update',
				component: () => import('@/pages/download-6.vue'),
			},
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-6.vue'),
			},
			{
				path: '/disclaimer',
				component: () => import('@/pages/disclaimer-6.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-6.vue'),
			},
		)
	}
	else if (baseURL.indexOf('waplusapk.download') >= 0) {
		baseRoutes.push(
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-9.vue'),
			},
			{
				path: '/disclaimer',
				component: () => import('@/pages/disclaimer-9.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-9.vue'),
			},
		)
	}
	else if (baseURL.indexOf('waproapk.com') >= 0) {
		baseRoutes.push(
			{
				path: '/id',
				component: () => import('@/components/home/domain-10id.vue'),
			},
			{
				path: '/pt',
				component: () => import('@/components/home/domain-10pt.vue'),
			},
			{
				path: '/gbwhatsapp-for-iphone',
				component: () => import('@/pages/iphone-10.vue'),
			},
			{
				path: '/id/gbwhatsapp-for-iphone',
				component: () => import('@/pages/iphone-10id.vue'),
			},
			{
				path: '/pt/gbwhatsapp-for-iphone',
				component: () => import('@/pages/iphone-10pt.vue'),
			},
			{
				path: '/whatsapp-plus-apk',
				component: () => import('@/pages/plus-10.vue'),
			},
			{
				path: '/id/whatsapp-plus-apk',
				component: () => import('@/pages/plus-10id.vue'),
			},
			{
				path: '/pt/whatsapp-plus-apk',
				component: () => import('@/pages/plus-10pt.vue'),
			},
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-10.vue'),
			},
			{
				path: '/disclaimer',
				component: () => import('@/pages/disclaimer-10.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-10.vue'),
			},
			{
				path: '/blogs',
				component: () => import('@/pages/blogIndex-10.vue'),
			},
			{
				path: '/id/blogs',
				component: () => import('@/pages/blogIndex-10id.vue'),
			},
			{
				path: '/pt/blogs',
				component: () => import('@/pages/blogIndex-10pt.vue'),
			},
			{
				path: '/download-gbwhatsapp-on-mac',
				component: () => import('@/components/blogs/blog10-1.vue'),
			},
			{
				path: '/id/download-gbwhatsapp-on-mac',
				component: () => import('@/components/blogs/blog10-1id.vue'),
			},
			{
				path: '/pt/download-gbwhatsapp-on-mac',
				component: () => import('@/components/blogs/blog10-1pt.vue'),
			},
			{
				path: '/change-themes-gbwhatsapp',
				component: () => import('@/components/blogs/blog10-2.vue'),
			},
			{
				path: '/id/change-themes-gbwhatsapp',
				component: () => import('@/components/blogs/blog10-2id.vue'),
			},
			{
				path: '/pt/change-themes-gbwhatsapp',
				component: () => import('@/components/blogs/blog10-2pt.vue'),
			},
			{
				path: '/download-gbwhatsapp',
				component: () => import('@/pages/download-10.vue'),
			},
			{
				path: '/id/download-gbwhatsapp',
				component: () => import('@/pages/download-10id.vue'),
			},
			{
				path: '/pt/download-gbwhatsapp',
				component: () => import('@/pages/download-10pt.vue'),
			},
		)
	}
	else if (baseURL.indexOf('gbwhatsapk.chat') >= 0) {
		baseRoutes.push(
			{
				path: '/privacy',
				component: () => import('@/pages/privacy-14.vue'),
			},
			{
				path: '/contact-us',
				component: () => import('@/pages/contact-14.vue'),
			},
			{
				path: '/about-us',
				component: () => import('@/pages/about-14.vue'),
			},
			{
				path: '/an-whatsapp',
				component: () => import('@/pages/an-14.vue'),
			},
			{
				path: '/ob-whatsapp',
				component: () => import('@/pages/ob-14.vue'),
			},
			{
				path: '/jt-whatsapp',
				component: () => import('@/pages/jt-14.vue'),
			},
			{
				path: '/blue-whatsapp',
				component: () => import('@/pages/blue-14.vue'),
			}
		)
	}
	
	// baseRoutes.push(
	// 	{
	// 		path: '/id',
	// 		component: () => import('@/components/home/domain-12id.vue'),
	// 	}
	// )
	return new Router({
		mode: 'history',
		routes: baseRoutes,
	})
}
