<template>
	<section class="domain_content">
		<h1>Privacy Policy</h1>
		<h2>Introduction</h2>
		<p>Welcome to GBWhatsApp.Com. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
		<h2>Personal Information</h2>
		<p>We do not collect personal information unless voluntarily provided by visitors. Any personal information collected will not be shared with third parties without your explicit consent. Please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
		<h2>Policy Update</h2>
		<p>We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy - GBapks.com'
const description = 'We are not the developers or owners of GB WhatsApp and are not responsible for any risks associated with its use. We advise you to consult the Privacy Policies of the third party for more detailed information.'
export default {
	name: 'PrivacyComponent',
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  