<template>
<!-- Table of Contents -->
<div id="toc_container" class="no_bullets">
    <section class="table-content" :class="{ hide: tableHide,show: !tableHide }">
        <div class="table-content-title" @click="tableHide = !tableHide">
            {{translatedTitle}}
            <img src="../assets/down.svg" alt="down" />
        </div>

        <ul class="toc_list" v-show="!tableHide">
            <li v-for="(item, index) in tocList" :key="index">
                <a :href="'#tbc_' + (index + 1)">
                    <span class="toc_number toc_depth_1">{{ index + 1 }}.</span>
                    {{ item }}
                </a>
            </li>
        </ul>
    </section>
    <!-- <section
            class="table-content hide"
            v-show="tableHide">
            <div
                class="table-content-title"
                @click="tableHide = !tableHide">
                {{translatedTitle}}

                <img
                    src="../assets/plus.svg"
                    alt="plus" />
            </div>
        </section> -->
</div>
</template>

<script>
export default {
    props: {
        tocList: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            default: 'en'
        }
    },
    data() {
        return {
            tableHide: true
        }
    },
    computed: {
        translatedTitle() {
            const titles = {
                en: "Table of Contents",
                es: "Tabla de Contenidos", // Spanish
                pt: "Tabela de Conteúdos", // Portuguese
                id: "Daftar Isi", // Bahasa Indonesia
                hi: "सामग्री की तालिका", // Hindi
                ar: "جدول المحتويات", // Arabic
                de: "Inhaltsverzeichnis" // German
            };

            // Return the title based on the selected language, default to English
            return titles[this.language] || titles.en;
        }
    }
}
</script>