<template>
<div class="faq">
    <div v-for="(item, index) in faqs" :key="index" class="dropdown-faq">
        <div class="faq-title" @click="toggleFaq(index)">
            <h4 >{{ item.question }}</h4>
            <img src="../assets/down.svg" alt="down" :class="{rotate: activeIndex===index}" loading="lazy">
        </div>

        <p v-if="activeIndex === index" class="content" v-html="item.answer">
        </p>
    </div>
</div>
</template>

<script>
export default {
    props: {
        faqs: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            activeIndex: null
        }
    },
    methods: {
        toggleFaq(index) {
            if (this.activeIndex === index) {
                this.activeIndex = null
            } else {
                this.activeIndex = index
            }
        }
    }
}
</script>
