<template>
<section class="domain_content">
    <h1 class="title">
        Buka Fitur Ekstra GB WhatsApp 2024
    </h1>

    <p class="pic-fit">
        <img src="@/assets/domain15-1.webp" alt="GB WhatsApp terverifikasi" title="Buka Fitur Ekstra GB WhatsApp 2024 1">
    </p>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="download" class="download-img" />
        <div class="download-name">Unduh GB WhatsApp</div>
    </div>

    <p>
        Dalam kehidupan modern, tidak ada yang bisa hidup tanpa ponsel dan aplikasi pesan di dalamnya. <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.whatsapp.com/">WhatsApp</a> adalah salah satu aplikasi pesan instan yang dapat membantu Anda tetap terhubung dengan orang lain. Hingga 2024, WhatsApp telah memperoleh miliaran pengguna di seluruh dunia. Kemudian, beberapa aplikasi mod untuk WhatsApp dikembangkan. Orang mencari fitur tambahan dan seseorang memenuhi kebutuhan mereka.
    </p>

    <p>
        GB WhatsApp adalah salah satu versi modifikasi terkenal dari WhatsApp asli. Ini tidak sepopuler itu tetapi dapat mengurangi risiko pemblokiran dibandingkan dengan aplikasi mod lainnya.
    </p>

    <TOC :tocList="toc" />

    <h2 id="tbc_1">
        {{toc[0]}}
    </h2>

    <p>
        GB WhatsApp sepenuhnya dikembangkan berdasarkan WhatsApp resmi. Pengguna dapat menggunakannya sebagai versi baru dari WhatsApp untuk menghubungi orang lain. Pesan yang dikirim oleh pengguna GB WhatsApp juga dapat diterima oleh pengguna WhatsApp. Mereka berbagi sistem yang sama.
    </p>

    <p>
        Jadi, mengetik pesan, melakukan panggilan video, mengirim pesan audio, membuat atau bergabung dengan grup adalah fitur yang tersedia di GB WhatsApp. Untuk fitur tambahan, kami membaginya menjadi tiga bagian. Silakan lanjutkan membaca.
    </p>

    <div class="features">
        <p class="pic-fit">
            <img src="@/assets/domain15-2.webp" alt="fitur kustom" title="Buka Fitur Ekstra GB WhatsApp 2024 2">
        </p>

        <p>
            <strong>Tema</strong> <br>
            Salah satu fitur populer dari GB WhatsApp adalah kustomisasi tema. Ini berarti pengguna dapat mengganti tema dari toko tema yang berisi lebih dari 4000 pilihan. Pengguna dapat mendesain warna, font, dan latar belakang obrolan di GB WhatsApp.
        </p>

        <p>
            <strong>Ikon Centang Biru</strong> <br>
            Sangat menakjubkan bahwa tanda pesan terbaca tradisional dapat diubah di GB WhatsApp. Pengembang menyediakan beberapa ikon untuk mengganti centang biru.
        </p>

        <p>
            <strong>Tandai sebagai Belum Dibaca</strong> <br>
            Pengguna dapat menandai pesan apa pun sebagai belum dibaca. Ini memudahkan pengguna untuk membaca pesan nanti ketika tidak memiliki waktu untuk membaca langsung.
        </p>

        <p>
            <strong>Filter Pesan</strong> <br>
            GB WhatsApp juga menambahkan fitur filter pesan untuk memfilter pesan teks atau gambar tertentu dengan mudah.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-3.webp" alt="fitur privasi" title="Buka Fitur Ekstra GB WhatsApp 2024 3">
        </p>

        <p>
            <strong>Mode Hantu</strong> <br>
            Ini tersembunyi di pengaturan GB WhatsApp. Pergi ke opsi privasi dan aktifkan opsi ini. Anda akan mendapatkan ikon hantu di bilah antarmuka utama. Kemudian, Anda dapat dengan mudah mengaktifkan atau menonaktifkan mode ini.
        </p>

        <p>
            <strong>Blokir Panggilan Masuk</strong> <br>
            Sesuai dengan nama fitur, panggilan masuk dapat diblokir selama Anda mengatur batasannya.
        </p>

        <p>
            <strong>Sembunyikan Centang Ganda Biru</strong> <br>
            Pernah bertanya-tanya apakah centang ganda bisa disembunyikan? Di GB WhatsApp, Anda bisa melakukannya. Centang biru bisa disembunyikan dan Anda dapat membaca pesan dengan bebas.
        </p>

        <p>
            <strong>Pesan Anti-Hapus/Direvokasi</strong> <br>
            GB WhatsApp menyisipkan fitur untuk mencegah pesan yang dihapus atau direvokasi. Anda tetap bisa melihat pesan yang dihapus atau direvokasi di kotak obrolan Anda. Dan pesan yang dihapus juga dapat dicari dalam riwayat obrolan.
        </p>

        <p>
            <strong>Sembunyikan Jejak Lihat</strong> <br>
            Pengguna dapat melihat status kontak tanpa memberi tahu mereka. Jejak lihat dapat disembunyikan.
        </p>

        <p>
            <strong>Sembunyikan Status Anda</strong> <br>
            Teks "sedang mengetik..." atau "merekam audio..." kepada kontak, grup, atau lainnya yang muncul di bilah atas kotak obrolan dapat disembunyikan di GB WhatsApp.
        </p>

        <p>
            <strong>Nonaktifkan Penerusan</strong> <br>
            Saat Anda meneruskan pesan atau foto dari obrolan lain, Anda dapat menghapus label Diteruskan.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-4.webp" alt="fitur lainnya" title="Buka Fitur Ekstra GB WhatsApp 2024 4">
        </p>
        <ul>
            <li>Kunci Aplikasi untuk keamanan</li>
            <li>Kirim gambar dan video hingga 700MB tanpa kompresi</li>
            <li>Kirim pesan langsung ke nomor telepon</li>
        </ul>
    </div>
    <h2 id="tbc_2">
        {{toc[1]}}
    </h2>
    <table>
        <tr>
            <th>Fitur</th>
            <th>GB WhatsApp</th>
            <th>WhatsApp</th>
        </tr>
        <tr>
            <td><strong>Mode Hantu</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Markah Pesan sebagai Belum Dibaca</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Sembunyikan Status</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Ubah Ticks Biru</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Sembunyikan Ticks Biru</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Blokir Panggilan Video</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Mode DND</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Baca Pesan yang Dihapus</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Ubah Tema</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Ubah Gelembung Chat</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Efek</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Markah sebagai Belum Dibaca</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Jadwalkan Pesan</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Ukuran Foto</strong></td>
            <td>100 MB</td>
            <td>100 MB</td>
        </tr>
        <tr>
            <td><strong>Kualitas Berkas Media</strong></td>
            <td>Tanpa Kompresi</td>
            <td>Kompresi</td>
        </tr>
        <tr>
            <td><strong>Filter Pesan</strong></td>
            <td>✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td><strong>Pusat Bantuan</strong></td>
            <td>❌</td>
            <td>✔️</td>
        </tr>
    </table>

    <h2 id="tbc_3">
        {{toc[2]}}
    </h2>

    <ul>
        <li><strong>Sebelum Mengunduh Berkas APK:</strong><br>
            Berkas APK dari pihak ketiga akan terdeteksi sebagai sumber tidak dikenal oleh sistem Android saat pemasangan. Jadi, pengguna harus mengaktifkan <strong>izinkan dari sumber ini</strong>. Ini juga dapat ditemukan di bawah "Izin Khusus" di ponsel Android.
            <p class="pic-fit landscape">
                <img src="@/assets/domain15-5.webp" alt="install aplikasi tidak dikenal" title="Buka Fitur Tambahan GB WhatsApp 2024 5">
            </p>
        </li>
        <li><strong>Instal GB WhatsApp:</strong><br>
            Setelah mengonfirmasi izin untuk memulai pemasangan GB WhatsApp, pengguna dapat mengikuti panduan untuk menyelesaikan prosesnya. Konfirmasi bahwa Anda menyadari risikonya dan berikan izin. Kemudian, Anda dapat menggunakan aplikasi tersebut.
        </li>
    </ul>

    <h2 id="tbc_4">
        {{toc[3]}}
    </h2>
    <p>
        Untuk mentransfer data dari WhatsApp ke GB WhatsApp, Anda harus terlebih dahulu membuat cadangan data. Pergi ke Privasi > Obrolan > Riwayat Obrolan, lalu atur frekuensi cadangan ke harian. Biasanya, data akan diunggah ke Google. Namun, GB WhatsApp tidak dapat mengakses data dari Google, karena tidak resmi.
    </p>

    <p>
        Ikuti langkah-langkah di bawah ini untuk menyelesaikan transfer:
        <ul>
            <li><strong>Langkah 1: </strong>Nonaktifkan cadangan ke akun Google. Tidak ada tombol untuk ini. Namun, Anda dapat mencoba memutuskan sambungan dari WiFi atau jaringan seluler.</li>
            <li><strong>Langkah 2: </strong>Masuk ke <strong>Pengelola Berkas</strong> di ponsel Android dan navigasikan ke <strong>Android > Media > com.whatsapp > WhatsApp</strong>. Data akan disimpan di sana. Anda dapat memeriksa apakah waktu pembaruan adalah hari Anda melakukan cadangan.
                <p class="pic-fit landscape">
                    <img src="@/assets/domain15-6.webp" alt="folder" title="Buka Fitur Tambahan GB WhatsApp 2024 6">
                </p>
            </li>
            <li><strong>Langkah 3: </strong>Salin folder <strong>Backups</strong>, <strong>Database</strong>, dan <strong>Media</strong> dan tempelkan ke folder lain. Ingat lokasi folder tempat Anda menempelkannya. Kami membutuhkannya di langkah-langkah berikutnya.</li>
            <li><strong>Langkah 4: </strong>Copot pemasangan WhatsApp. Jika Anda khawatir, Anda dapat memilih untuk tidak menghapus cache.</li>
            <li><strong>Langkah 5: </strong>Buat folder bernama <strong>com.gbwhatsapp</strong> di bawah <strong>Media</strong>. Buat folder <strong>GB WhatsApp</strong> di bawahnya. Kemudian pindahkan folder <strong>Backups</strong>, <strong>Database</strong>, dan <strong>Media</strong> ke dalamnya.</li>
            <li><strong>Langkah 6: </strong>Kembali ke antarmuka ponsel dan luncurkan GB WhatsApp, Anda dapat memulihkan data kali ini.</li>
        </ul>
    </p>

    <h2 id="tbc_5">
        {{toc[4]}}
    </h2>
    <ol>
        <li>Perbarui ke versi terbaru dari dalam GB WhatsApp: Periksa versi GB WhatsApp saat ini di Info Aplikasi, jika ada versi baru dirilis, akan ada pemberitahuan untuk memperbarui.</li>
        <li>Unduh versi terbaru dari Situs Web: Kunjungi situs GB WhatsApp terpercaya ini untuk mengunduh file APK terbaru.</li>
    </ol>
    <h4 style="text-align:center;">
        Informasi Aplikasi
    </h4>
    <table>
        <tr>
            <th>Nama</th>
            <th>GB WhatsApp</th>
        </tr>
        <tr>
            <td>Ukuran</td>
            <td>77MB</td>
        </tr>
        <tr>
            <td>Versi</td>
            <td>18.10</td>
        </tr>
        <tr>
            <td>Pembaruan</td>
            <td>1 hari yang lalu</td>
        </tr>
        <tr>
            <td>Lisensi</td>
            <td>Gratis</td>
        </tr>
    </table>
    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="unduh" class="download-img" />
        <div class="download-name">Unduh GB WhatsApp 18.10</div>
    </div>
    <h2 id="tbc_6">
        {{toc[5]}}
    </h2>
    <p>
        <strong>Akun GB WhatsApp:</strong> Karena WhatsApp resmi dan GB WhatsApp berbagi akun yang sama, dan tim WhatsApp resmi telah meningkatkan mekanisme anti-modifikasi mereka. Pengguna GB WhatsApp mungkin menghadapi risiko pemblokiran akun. Ini tidak bisa dihindari, jadi banyak pengguna memilih menggunakan nomor telepon sekunder untuk masuk ke aplikasi GB WhatsApp.
    </p>
    <p>
        <strong>Kebocoran Informasi Pribadi:</strong> Kekhawatiran bervariasi dari orang ke orang. Beberapa di antaranya menginstal tidak hanya satu, tetapi beberapa aplikasi modifikasi atau crack. Beberapa sangat berhati-hati tentang informasi pribadi. Tidak ada bukti langsung yang menjelaskan masalah pencurian informasi.
    </p>

    <h2 id="tbc_7">
        {{toc[6]}}
    </h2>
    <FAQ :faqs="faqs" />

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import TOC from '@/components/TOC.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    ...{
        "metaInfo": {
            "title": "Safe Download GB WhatsApp 2024 to Unlock Extra Features",
            "meta": [{
                "name": "description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }, {
                "name": "title",
                "content": "GBWhatsApp"
            },
             {
                "property": "og:title",
                "content": "GBWhatsApp"
            }, {
                "property": "og:description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapps.homes/id/"
            }]
        }
    },
    components: {
        TOC,
        FAQ
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            language: 'id',
            toc: [
                'Mengapa orang memilih menggunakan GB WhatsApp?',
                'Perbandingan GB WhatsApp dan WhatsApp',
                'Unduh dan pasang GB WhatsApp',
                'Transfer data dari WhatsApp ke GB WhatsApp',
                'Metode untuk memperbarui file APK GB WhatsApp',
                'Masalah keamanan: Apakah GB WhatsApp aman?',
                'FAQ'
            ],
            faqs: [{
                    question: 'Apa yang baru di versi terbaru GB WhatsApp?',
                    answer: 'Versi terbaru GB WhatsApp memperbaiki beberapa bug dan meningkatkan perlindungan anti-banned.',
                },
                {
                    question: 'Bisakah saya menggunakan versi lama GB WhatsApp?',
                    answer: 'Jika Anda menggunakan versi lama, Anda dapat tetap menggunakannya. Namun, jangan lupa untuk secara rutin mencadangkan data. Jika versi tersebut terlalu lama, Anda dapat memperbaruinya ke versi terbaru.',
                },
                {
                    question: 'GB WhatsApp untuk iPhone',
                    answer: 'GB WhatsApp tidak mendukung sistem iOS. Pengguna iPhone tidak bisa menggunakannya. Jika Anda tertarik, Anda bisa mencari cara untuk memasang sistem Android di YouTube.',
                },
                {
                    question: 'Apa itu Blue WhatsApp Plus?',
                    answer: 'Tim BlueMods mengembangkan Blue WhatsApp Plus dengan lebih banyak fitur dibandingkan WhatsApp resmi. Ini adalah aplikasi versi MOD lainnya. Fitur yang sama dapat ditemukan di dalamnya tetapi dengan sedikit perbedaan pada logo dan antarmuka.',
                },
            ]
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
