import axios from 'axios';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 根据需要设置基础URL
  timeout: 30000, // 请求超时时间
  withCredentials: true // 允许携带cookie
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加额外的头部信息，例如认证令牌
    return config;
  },
  error => {
    // 请求发起错误处理
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做处理
    return response.data;
  },
  error => {
    // 响应错误处理
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default {
  get(url, params) {
    return service.get(url, { params });
  },
  post(url, data) {
    return service.post(url, data);
  }
};