<template>
  <!-- Recent Posts -->
  <div id="rec_post_container" class="no_bullets">
    <h3>
      {{ titleLang[language] }}
    </h3>
    <div class="rec_post_list">
      <div v-if="validSite(['waproapk.com'])" class="rec_post_item">
        <div v-for="(item, index) in sortedBlogList" :key="index">
          <router-link :to="makeURL(item.url)">
            <div class="rec_post_content">
              {{ item.title }}
            </div>
          </router-link>
        </div>
      </div>
      <div v-else class="rec_post_item">
        <div v-for="(item, index) in sortedBlogList" :key="index">
          <a :href="item.url">
            <div class="rec_post_content">
              {{ item.title }}
            </div>
          </a>
        </div>
      </div>

      <div v-if="validSite(['whatsapppro.org'])" class="rec_post_item">
        <a href="/gb-whatsapp-for-pc">
          <div class="rec_post_content">
            GB WhatsApp for PC
          </div>
        </a>
      </div>
      <div v-if="validSite(['whatsapppro.org'])" class="rec_post_item">
        <a href="/gb-whatsapp-old-versions">
          <div class="rec_post_content">
            GB WhatsApp Old Versions
          </div>
        </a>
      </div>
      <div v-if="validSite(['waproapk.com'])" class="rec_post_item">
        <router-link :to="makeURL('gbwhatsapp-for-iphone')">
          <div class="rec_post_content">
            GB WhatsApp for iPhone
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/default.scss';
import {
  mapGetters
} from 'vuex';
export default {
  props: {
    doc: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      titleLang: {
        "en": "Recent Posts",
        "es": "Publicaciones Recientes", // Spanish
        "pt": "Postagens Recentes", // Portuguese
        "id": "Pos Terbaru", // Bahasa Indonesia
        "hi": "हाल के पोस्ट", // Hindi
        "ar": "المنشورات الأخيرة", // Arabic
        "de": "Aktuelle Beiträge" // German
      },
    }
  },

  computed: {
    ...mapGetters(['showDebug', 'host']),
    sortedBlogList () {
      // console.log('language: ', this.language)
      // console.log('doc: ', this.doc.blogList[this.language])
      // Check if doc.blogList and the language exist
      if (this.doc.blogList && this.doc.blogList[this.language]) {
        return this.doc.blogList[this.language].slice().reverse();
      }
      // Return an empty array if the check fails
      return [];
    },
    makeURL () {
      if (this.language === 'en') {
        return (param) => `/${param}`;
      } else {
        return (param) => `/${this.language}/${param}`;
      }
    }
  },
  methods: {
    validSite (arr) {
      // console.log('validSite: ',arr)

      if (this.host.length === 0) {
        return true
      }

      return arr.some((e) => {
        return this.host.indexOf(e) >= 0
      })
    },
  }
}
</script>
