<template>
<section class="domain_content">
  <h1>{{ doc.blogList['id'][0].title }}</h1>

  <h2>
    Cara Mengunduh dan Menginstal WhatsApp GB Pro di Mac?
  </h2>

  <p>
    Jika Anda sudah menjadi pengguna WhatsApp GB Pro dan ingin mengunduh WhatsApp GB Pro di Mac, Anda mungkin sudah tahu bahwa kemungkinannya hampir nol. Tidak ada versi Mac dari WhatsApp GB Pro.
  </p>

  <p>
    Namun, beberapa pengguna aplikasi mod jenis ini menemukan cara lain untuk mengatasi masalah ini, yaitu dengan menginstal WhatsApp GB Pro di Mac melalui BlueStacks.
  </p>
  <p>
    Sekarang, ikuti panduan kami untuk mengunduh dan menginstal WhatsApp GB Pro di Mac atau Windows (7,8,10,11) langkah demi langkah.
  </p>
  <p>
    <img class="wi" alt="WhatsApp GB pro untuk mac" src="@/assets/blog10-1.webp" />
  </p>

  <h2>
    Langkah-langkah untuk menginstal WhatsApp GB Pro di Mac
  </h2>
  <h6>
    Instal BlueStacks di Mac
  </h6>
  <ol>
    <li>
      Pertama, Anda harus mengunduh emulator Android BlueStacks. Kunjungi situs resmi: <a href="https://www.bluestacks.com/" target="_blank" ref="nofollow">https://www.bluestacks.com/</a>
    </li>
    <li>
      Ikuti petunjuk di layar dan instal BlueStacks.
    </li>
  </ol>
  <h6>
    Instal GBWhatsApp Pro di Mac
  </h6>
  <ol>
    <li>
      Unduh <a href="/id" target="_blank">versi terbaru GBWhatsApp Pro</a> dari situs terpercaya.
    </li>
    <li>
      Klik "Instal APK di BlueStacks". Untuk detail lebih lanjut, Anda dapat membaca panduan pengguna BlueStacks.
    </li>
    <li>
      Sekarang, Anda dapat meluncurkan WhatsApp GB dan memverifikasi nomor telepon Anda.
    </li>
  </ol>

  <h2>
    Fitur Utama WhatsApp GB Pro
  </h2>
  <p>
    Ingat fitur WhatsApp GB Pro untuk Android? Sekarang Anda dapat menggunakannya di Mac:
  </p>
  <ul>
    <li>Sembunyikan status online</li>
    <li>Bagikan gambar resolusi tinggi</li>
    <li>Bagikan file besar</li>
    <li>Baca pesan yang dihapus</li>
    <li>Unduh status</li>
    <li>Ganti tema</li>
    <li>Mode DND</li>
    <li>Ganti wallpaper</li>
    <li>Sembunyikan Centang Biru</li>
  </ul>

  <RecentPost :doc="doc" :language="language" />
</section>
</template>

<script>
import '@/css/default.scss';
import doc from '@/documents/blog10.js';
import RecentPost from '@/components/RecentPost.vue';
import {
  mapGetters
} from 'vuex';
const title = 'WhatsApp GB Pro untuk Mac: Langkah-langkah untuk Mengunduh dan Menginstal';
const description = 'Bagaimana cara menggunakan WhatsApp GB Pro di Mac? Di sini kami mencantumkan metode sederhana tetapi efektif untuk membantu pengguna mengunduh dan menginstal WhatsApp GB Pro di Mac.';
export default {
  data() {
    return {
      doc: doc,
      from: null,
      filename: null,
      partners: null,
      updateTime: null,
      clicks: null,
      pageIntSuccess: null,
      apk: null,
      language: 'id'
    };
  },
  metaInfo: {
    title,
    meta: [{
        name: 'description',
        content: description,
      },
      {
        name: 'title',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
    ],
    "link": [{
      "rel": "canonical",
      "href": "https://waproapk.com/id/download-gbwhatsapp-on-mac/"
    }]
  },
  components: {
    RecentPost,
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'host',
      "downloadicon"
    ])
  },
  created() {
    this.initParams();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    download(apk) {
      this.$global.download(apk);
    },
    initParams() {
      this.from = this.$global.GetQueryString('from');
      this.filename = this.$global.GetQueryString('filename');
    },
    fetchData() {
      if (this.from) {
        this.getAPK(true, this.from, this.filename);
      } else {
        this.getAPK();
        this.getOfficialApk();
      }
    },
    updateAPK() {
      this.$emit('update-apk', this.apk);
    },
    getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
      this.$server
        .getAPPInfo({
          promotionChannel: from,
          promotionName: filename
        })
        .then((res) => {
          if (res.code === 200) {
            this.partners = res.data.partners;
            this.updateTime = res.data.updateTime;
            this.clicks = res.data.clicks;
            this.pageIntSuccess = true;
            if (isFromQuery) {
              this.apk = res.data.apk;
              this.updateAPK();
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching APK:', err);
        });
    },
    getOfficialApk() {
      this.$server
        .getOfficialApk({
          domain: 'gbpro.download',
          appName: 'GB_gbpro.download'
        })
        .then((res) => {
          if (res.code === 200) {
            this.apk = res.data.apk;
            this.updateAPK();
            console.log('APK fetched successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching official APK:', err);
        });
    }
  }
};
</script>
