<template>
<section class="domain_content">
  <h1>{{ doc.blogList['pt'][0].title}}</h1>

  <h2>
    Como Baixar e Instalar o WhatsApp GB Pro no Mac?
  </h2>

  <p>
    Se você já é um usuário do WhatsApp GB Pro e gostaria de baixá-lo no Mac, você já deve saber que a possibilidade é zero. Não existe uma versão do WhatsApp GB Pro para Mac.
  </p>

  <p>
    No entanto, alguns usuários desse tipo de aplicativo mod encontraram outra maneira de resolver esse problema: instalar o WhatsApp GB Pro no Mac via BlueStacks.
  </p>

  <p>
    Agora, siga nosso guia para baixar e instalar o WhatsApp GB Pro no Mac ou Windows (7,8,10,11) passo a passo.
  </p>

  <p>
    <img class="wi" alt="WhatsApp GB Pro para Mac" src="@/assets/blog10-1.webp" />
  </p>

  <h2>
    Passos para instalar o WhatsApp GB Pro no Mac
  </h2>
  <h6>
    Instale o BlueStacks no Mac
  </h6>
  <ol>
    <li>
      Primeiramente, você deve baixar o emulador Android BlueStacks. Acesse o site oficial: <a href="https://www.bluestacks.com/" target="_blank" ref="nofollow">https://www.bluestacks.com/</a>
    </li>
    <li>
      Siga as instruções na tela e instale o BlueStacks.
    </li>
  </ol>

  <h6>
    Instale o WhatsApp GB Pro no Mac
  </h6>
  <ol>
    <li>
      Baixe a <a href="/pt" target="_blank">versão mais recente do WhatsApp GB Pro</a> de um site confiável.
    </li>
    <li>
      Clique em "Instalar APK no BlueStacks". Para mais detalhes, você pode ler o guia do usuário do BlueStacks.
    </li>
    <li>
      Agora, você pode abrir o WhatsApp GB e verificar seu número de telefone.
    </li>
  </ol>

  <h2>
    Principais Recursos do WhatsApp GB Pro
  </h2>
  <p>
    Lembra dos recursos do WhatsApp GB Pro para Android? Agora você pode usá-los no Mac:
  </p>
  <ul>
    <li>Ocultar status online</li>
    <li>Compartilhar imagens em alta resolução</li>
    <li>Compartilhar arquivos grandes</li>
    <li>Ler mensagens apagadas</li>
    <li>Baixar status</li>
    <li>Alterar temas</li>
    <li>Modo DND</li>
    <li>Alterar papel de parede</li>
    <li>Ocultar tique azul</li>
  </ul>

  <RecentPost :doc="doc" :language="language" />
</section>
</template>

<script>
import '@/css/default.scss';
import doc from '@/documents/blog10.js';
import RecentPost from '@/components/RecentPost.vue';
import {
  mapGetters
} from 'vuex';
const title = 'WhatsApp GB Pro para Mac: Passos para Baixar e Instalar'
const description = 'Como usar o WhatsApp GB Pro no Mac? Aqui listamos métodos simples, mas eficazes, para ajudar os usuários a baixar e instalar o WhatsApp GB Pro no Mac.'
export default {
  data() {
    return {
      doc: doc,
      from: null,
      filename: null,
      partners: null,
      updateTime: null,
      clicks: null,
      pageIntSuccess: null,
      apk: null,
      language: 'pt'
    };
  },
  metaInfo: {
    title,
    meta: [{
        name: 'description',
        content: description,
      },
      {
        name: 'title',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
    ],
    "link": [{
      "rel": "canonical",
      "href": "https://waproapk.com/pt/download-gbwhatsapp-on-mac/"
    }]
  },
  components: {
    RecentPost,
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'host',
      "downloadicon"
    ])
  },
  created() {
    this.initParams();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    download(apk) {
      this.$global.download(apk);
    },
    initParams() {
      this.from = this.$global.GetQueryString('from');
      this.filename = this.$global.GetQueryString('filename');
    },
    fetchData() {
      if (this.from) {
        this.getAPK(true, this.from, this.filename);
      } else {
        this.getAPK();
        this.getOfficialApk();
      }
    },
    updateAPK() {
      this.$emit('update-apk', this.apk);
    },
    getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
      this.$server
        .getAPPInfo({
          promotionChannel: from,
          promotionName: filename
        })
        .then((res) => {
          if (res.code === 200) {
            this.partners = res.data.partners;
            this.updateTime = res.data.updateTime;
            this.clicks = res.data.clicks;
            this.pageIntSuccess = true;
            if (isFromQuery) {
              this.apk = res.data.apk;
              this.updateAPK();
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching APK:', err);
        });
    },
    getOfficialApk() {
      this.$server
        .getOfficialApk({
          domain: 'gbpro.download',
          appName: 'GB_gbpro.download'
        })
        .then((res) => {
          if (res.code === 200) {
            this.apk = res.data.apk;
            this.updateAPK();
            console.log('APK fetched successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching official APK:', err);
        });
    }
  }
};
</script>
