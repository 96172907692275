// entry-client.js
import createApp from './src/app'

const baseURL = window.location.href
console.log(`client 打印的url地址 用于传递router>>${baseURL}`)
const { app, router, store } = createApp(baseURL)

// // 检查是否存在服务器端注入的状态
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
	app.$mount('#app')
})
