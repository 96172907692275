import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'mobile',
			showDebug: false,
			downloadicon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMAMu0fGje6X9aHE7YF+vXmdVNGIwrexyvSq2acmKYMab8AAAElSURBVGje7dZLcoMwFETRNj+DLDDiY2yM97/NlJMhVOlJLQ+SvLOBO+tqKPUfTOcdg9SK106pEY1oRCMa0YhGNPJHIyY/Vu4jLj9m4ZNfX6SLhdeScY3bBIGSqtwNRIY2vjEbCLnoxnWE2DmykS0I0Ec1uhJBmohGOyDQFh5xCPYIbawIZy9hjR4xpltIo4ndyru8scUv8ixtPBBvFE5yZUFYMuHwUsqOG95UkzwXoDnB8PJWwfDyesHw8hrB8PKexPDSk7wiJVsRw0tNcgMffpKfSK+YxcNLTbJ0eLlJDhlefpJrg48ZWv/w8pxwePlJzk74sF4wvLxmoKa2futB2+o3gyNVqkH6+beFRn5VpMtorSeShkY0ohGNfBtPSVkopZQS+AIzUiXWWQxhbwAAAABJRU5ErkJggg==',
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			downloadicon: (state) => state.downloadicon,
		},
	})
}
