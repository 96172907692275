<template>
<section class="domain_content">
  <h1>{{ doc.blogList[language][1].title}}</h1>

  <p> WhatsApp GB telah menarik banyak pengguna, sebagian besar berkat fitur kustomisasi yang mengesankan. Di antara fitur-fitur ini, kemampuan untuk mengatur tema sangat populer. Fitur ini menarik pengguna yang ingin tampilan dan nuansa WhatsApp yang lebih personal. </p>
  <p> Sebagai versi MOD yang dibangun di atas kerangka asli WhatsApp, WhatsApp GB tetap sepenuhnya kompatibel dengan aplikasi resmi. </p>
  <p> WhatsApp GB secara aktif mendengarkan umpan balik pengguna dan terus menambahkan berbagai fitur kustomisasi untuk meningkatkan pengalaman pengguna. </p>
  <p> <img class="wi" alt="ubah tema WhatsApp" src="@/assets/blog10-2.webp" /> </p>
  <h2> Cara Mengganti Tema di WhatsApp GB </h2>
  <h6>1. Instal dan login ke akun di WhatsApp GB:</h6>
  <ul>
    <li>Jika Anda belum menginstal WhatsApp GB, kunjungi situs terpercaya kami untuk mengunduh versi APK terbaru secara aman.</li>
    <li>Jika Anda sudah login, Anda dapat langsung melanjutkan ke langkah berikutnya.</li>
  </ul>
  <h6>2. Akses Pengaturan Tema:</h6>
  <ul>
    <li>Buka WhatsApp GB dan ketuk tiga titik vertikal di sudut kanan atas untuk mengakses menu.</li>
    <li>Dari menu dropdown, pilih GB Settings.</li>
    <li>Setelah berada di menu pengaturan, ketuk <strong>Themes > Download Themes</strong> untuk mengakses opsi tema.</li>
  </ul>
  <h6>3. Jelajahi dan Terapkan Tema:</h6>
  <ul>
    <li>Jelajahi perpustakaan yang luas dengan lebih dari 4.000 tema atau gunakan bilah pencarian untuk menemukan tema spesifik.</li>
    <li>Setelah Anda memilih tema yang disukai, ketuk <strong>Install</strong> untuk menerapkannya.</li>
  </ul>
  <p class="pic-fit landscape"> <img src="@/assets/themes.webp" alt="Pilih tema" /> </p>
  <h6>4. Mulai Ulang WhatsApp GB:</h6>
  <p>Setelah menerapkan tema, Anda mungkin perlu memulai ulang WhatsApp GB agar perubahan terlihat.</p>
  <h2> Kesimpulan </h2>
  <p> Mengatur tema adalah cara sederhana untuk mempersonalisasi WhatsApp GB. Dengan mengikuti langkah-langkah di atas, pengguna dapat dengan mudah menemukan tema yang disukai di antara banyaknya tema yang disediakan oleh WhatsApp GB. </p>
  <p> Namun, untuk menghindari risiko keamanan, penting untuk mengunduh WhatsApp GB hanya dari sumber yang terpercaya dan aman. Situs web kami memastikan Anda selalu memiliki akses ke versi aplikasi terbaru dan teraman. </p>

  <RecentPost :doc="doc" :language="language" />

</section>
</template>

<script>
import '@/css/default.scss';
import doc from '@/documents/blog10.js';
import RecentPost from '@/components/RecentPost.vue';
import {
  mapGetters
} from 'vuex';
const title = 'Ubah Tema di WhatsApp GB: Panduan untuk Pengguna'
const description = 'WhatsApp GB menyediakan lebih dari 4000 tema untuk pengguna. Pengguna dapat memilih tema yang mereka sukai dan menginstalnya. Untuk cara mengganti tema WhatsApp GB, kami menyediakan beberapa langkah untuk membantu Anda melakukannya.'

export default {
  data() {
    return {
      doc: doc,
      from: null,
      filename: null,
      partners: null,
      updateTime: null,
      clicks: null,
      pageIntSuccess: null,
      apk: null,
      language: 'id',
    };
  },
  metaInfo: {
    title,
    meta: [{
        name: 'description',
        content: description,
      },
      {
        name: 'title',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
    ],
    "link": [{
      "rel": "canonical",
      "href": "https://waproapk.com/id/change-themes-gbwhatsapp/"
    }]
  },
  components: {
    RecentPost,
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'host',
      "downloadicon"
    ])
  },
  created() {
    this.initParams();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    download(apk) {
      this.$global.download(apk);
    },
    initParams() {
      this.from = this.$global.GetQueryString('from');
      this.filename = this.$global.GetQueryString('filename');
    },
    fetchData() {
      if (this.from) {
        this.getAPK(true, this.from, this.filename);
      } else {
        this.getAPK();
        this.getOfficialApk();
      }
    },
    updateAPK() {
      this.$emit('update-apk', this.apk);
    },
    getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
      this.$server
        .getAPPInfo({
          promotionChannel: from,
          promotionName: filename
        })
        .then((res) => {
          if (res.code === 200) {
            this.partners = res.data.partners;
            this.updateTime = res.data.updateTime;
            this.clicks = res.data.clicks;
            this.pageIntSuccess = true;
            if (isFromQuery) {
              this.apk = res.data.apk;
              this.updateAPK();
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching APK:', err);
        });
    },
    getOfficialApk() {
      this.$server
        .getOfficialApk({
          domain: 'gbpro.download',
          appName: 'GB_gbpro.download'
        })
        .then((res) => {
          if (res.code === 200) {
            this.apk = res.data.apk;
            this.updateAPK();
            console.log('APK fetched successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching official APK:', err);
        });
    }
  }
};
</script>
