export default {
    blogList: {
        "en":
        [{
            title: 'How to Download and Install GB WhatsApp Pro on Mac?',
            desc: 'If you are alread a GB WhatsApp Pro user and would like to download GB WhatsApp Pro on Mac. You might already know the possibility is zero. There is no Mac version of GB WhatsApp Pro.',
            url: 'download-gbwhatsapp-on-mac',
            img: require('@/assets/blog10-1.webp'),
            alt: 'GB WhatsApp pro for mac'
        },
        {
            title: 'Guide to Change Themes in GB WhatsApp',
            desc: 'GB WhatsApp has gained a large user base, thanks in part to its impressive customization features. Among these, the ability to set themes is particularly popular. This feature attracts many users who seek a personalized look and feel for WhatsApp.',
            url: 'change-themes-gbwhatsapp',
            img: require('@/assets/blog10-2.webp'),
            alt: 'change WhatsApp themes'
        }],
        "id":[{
            title: 'Cara Download dan Install WhatsApp GB Pro di Mac?',
            desc: 'Jika Anda adalah pengguna WhatsApp GB Pro dan ingin mendownload WhatsApp GB Pro di Mac. Anda mungkin sudah tahu kemungkinannya nol. Tidak ada versi Mac dari WhatsApp GB Pro.',
            url: 'download-gbwhatsapp-on-mac',
            img: require('@/assets/blog10-1.webp'),
            alt: 'WhatsApp GB pro for mac'
        },
        {
            title: 'Panduan Mengganti Tema di WhatsApp GB',
            desc: 'WhatsApp GB telah mendapatkan basis pengguna yang besar, tidak terkecuali berkat fitur kustomisasi yang mengesankan. Di antara fitur-fitur ini, kemampuan untuk mengatur tema sangat populer. Fitur ini menarik banyak pengguna yang mencari tampilan dan nuansa yang dipersonalisasi untuk WhatsApp.',
            url: 'change-themes-gbwhatsapp',
            img: require('@/assets/blog10-2.webp'),
            alt: 'change WhatsApp themes'
        }],
        "pt":[{
            title: 'Como Baixar e Instalar o WhatsApp GB Pro no Mac?',
            desc: 'Se você já é um usuário do WhatsApp GB Pro e gostaria de baixar o WhatsApp GB Pro no Mac. Você já deve saber que a possibilidade é zero. Não há versão do Mac do WhatsApp GB Pro.',
            url: 'download-gbwhatsapp-on-mac',
            img: require('@/assets/blog10-1.webp'),
            alt: 'WhatsApp GB pro for mac'
        },
        {
            title: 'Guia para Mudar Temas no WhatsApp GB',
            desc: 'O WhatsApp GB ganhou uma grande base de usuários, graças em parte a seus impressionantes recursos de personalização. Entre esses, a capacidade de definir temas é particularmente popular. Este recurso atrai muitos usuários que buscam uma aparência e sensação personalizadas para o WhatsApp.',
            url: 'change-themes-gbwhatsapp',
            img: require('@/assets/blog10-2.webp'),
            alt: 'change WhatsApp themes'
        }]
},
    urlMap: {
        0: 'download-gbwhatsapp-on-mac',
        1: 'change-themes-gbwhatsapp',
    }
}