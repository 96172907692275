<template>
	<div class="wrapper domain_content blogIndex bgcolor">
		<div class="head">
			<img
				src="@/assets/shiny-logo.webp"
				id="logo"
				alt="logo" />
			<h1>GB WhatsApp</h1>
			<h4 class="s1">Download GB WhatsApp Latest Version APK 2024 (Anti-Ban)</h4>
			<div
				style="width: 60%; background: #000"
				exp-data="show_gbhome_download"
				@click="download(apk)"
				class="big-download-btn top-download fix has-exp"
				data-exposure="1">
				<div class="download-name">Download GB WhatsApp</div>
			</div>
			<h4 class="s2">V18.10 | Security Verified</h4>
		</div>
		
		<div class="list">
			<div @click="go(i)" class="blog" v-for="(item, i) in blogs" :key="item.title">
				<img
					v-if="deviceType !== 'mobile'"
					:src="require(`@/assets/blog${i+1}.webp`)"/>
				<div class="text">
					<h4>{{ item.title }}</h4>
					<p>{{ item.desc }}</p>
				</div>
			</div>
	
		</div>
	</div>
</template>

<script>
import '@/css/default.scss'
import { mapGetters } from 'vuex'
const title = 'Latest Blogs about GB WhatsApp Keep Updating '
const description='In the blog part, our site will update news or popular features about GB WhatsApp for users. Enter this section to get more details about GB WhatsApp.'
export default {
	metaInfo : {
      title,
      meta: [
        {
		   name: 'description'	,
		   content: description
        },
		{
			name: 'title',
			content: title,
		},
		{
			property: "og:title",
			content: title
		},
		{
			property: "og:description",
			content: description
		},
      ]
    },
	data() {
		return {
			from: null,
			filename: null,
			partners: null,
			updateTime: null,
			clicks: null,
			pageIntSuccess: null,
			apk: null,
			blogs: [
				{
					title: 'The Latest Version of GB WhatsApp APK',
					desc: 'Regular users eagerly anticipating the latest version of GB WhatsApp can now expect a positive response. Download the GB WhatsApp APK now!'
				},
				{
					title: 'Download GB WhatsApp Pro 2024 Anti-Ban Version',
					desc: 'GB WhatsApp Pro updated a new version to protect users account amidst stringent bans enforced by official WhatsApp. Download GB WhatsApp Pro APK in this post for free. '
				},
				{
					title: 'How to use GB WhatsApp 2024 without Getting Banned?',
					desc:'To avoid the risk of being banned under WhatsApp\'s enhanced anti-ban measures, we have introduced several methods to use GBWhatsApp safely.'
				},
				{
					title:'WhatsApp VS GB WhatsApp: Understanding the Differences',
					desc:'Why did GB WhatsApp gain millions of users? What features does it have compared to the original WhatsApp? Read this post to explore the differences between WhatsApp and GB WhatsApp.'
				},
				{
					title:'Change Themes in GB WhatsApp: A Guide for Users',
					desc:'GB WhatsApp provide users with over 4000 themes. Users can choose one they like and install it . As for how to change the themes of GB WhatsApp, we put some steps to help you change it.  '
				},
				{
					title:'Methods to Use GB WhatsApp on Web or Desktop',
					desc:'We introduce an alternative method to help users use GBWhatsApp on a desktop, even though GBWhatsApp only supports the Android platform. Read on to learn more about it.'
				},
				{
					title:'Android Users Can\'t Miss: Top 5 Messaging Apps in 2024',
					desc:' With the appearance of new messaging apps, we evaluated the pros and cons of the apps for Android users. To experience the difference between the 5 apps, you can continue reading. '
				},
			]
		}
	},
	computed: {
		...mapGetters(['showDebug', 'host', 'downloadicon', 'deviceType']),
	},
	created() {
		this.initParams()
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		go(index) {
			const urlMap = {
				0: 'gb-whatsapp-download',
				1: 'gbwhatsapp-pro-download',
				2: 'use-gbwhatsapp-without-getting-ban',
				3: 'difference-between-gbwhatsapp-whatsapp',
				4: 'change-gbwhatsapp-themes',
				5: 'gbwhatsapp-web-desktop',
				6: '5-messaging-apps'
			}
			this.$router.push(urlMap[index])
			// const url = this.$router.resolve({
			// 	path: urlMap[index],
			// })
			// console.log(url.href, 8888) // 输出为#/test-url
			// // 打开新窗口
			// window.open(url.href)
		},
		download(apk) {
			this.$global.download(apk)
		},
		initParams() {
			this.from = this.$global.GetQueryString('from')
			this.filename = this.$global.GetQueryString('filename')
		},
		fetchData() {
			if (this.from) {
				this.getAPK(true, this.from, this.filename)
			} else {
				this.getAPK()
				this.getOfficialApk()
			}
		},
		updateAPK() {
			this.$emit('update-apk', this.apk)
		},
		getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
			this.$server
				.getAPPInfo({
					promotionChannel: from,
					promotionName: filename,
				})
				.then((res) => {
					if (res.code === 200) {
						this.partners = res.data.partners
						this.updateTime = res.data.updateTime
						this.clicks = res.data.clicks
						this.pageIntSuccess = true
						if (isFromQuery) {
							this.apk = res.data.apk
							this.updateAPK()
						}
					}
				})
				.catch((err) => {
					console.error('Error fetching APK:', err)
				})
		},
		getOfficialApk() {
			this.$server
				.getOfficialApk({
					domain: 'gbpro.download',
					appName: 'GB_gbpro.download',
				})
				.then((res) => {
					if (res.code === 200) {
						this.apk = res.data.apk
						this.updateAPK()
						console.log('APK fetched successfully')
					}
				})
				.catch((err) => {
					console.error('Error fetching official APK:', err)
				})
		},
	},
}
</script>

