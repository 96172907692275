/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2024-06-25 14:18:27
 */

// import { api } from './service.js'
import service from './service.js'

const server = {
	getAPK(params) {
		return service.get(`${process.env.VUE_APP_GETAPP_API}/promote`, params)
	},

	getAppList(data) {
		return service.post(`${process.env.VUE_APP_GATEWAPP_API}/external/content/40201/api`, data)
	},

	getAPPInfo(data) {
		return service.post(`${process.env.VUE_APP_H5_API}/official`, data)
	},

	getOfficialApk(data) {
		return service.post(`${process.env.VUE_APP_H5_API}/apk`, data)
	},

	getAppScore(params) {
		return service.get(`${process.env.VUE_APP_FEEDBACK_API}/as/q`, params)
	},

	saveAppScore(data) {
		return service.post(`${process.env.VUE_APP_FEEDBACK_API}/as/s`, data)
	},

	init(data) {
		return service.post(`${process.env.VUE_APP_H5_API}/init`, data)
	},

	getADetail(params) {
		return service.get(`${process.env.VUE_APP_H5_API}/website/get`, params)
	},
}

function download(apk, name) {
	// // 创建隐藏的可下载链接
	if (apk.includes('www.gbwhatsapp.download')) {
		apk = apk.replace('www.gbwhatsapp.download', 'cdn.gbwhatsapp.download')
	}

	let eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)

	// eleLink.setAttribute('download', name)
	eleLink.style.display = 'none'
	// eleLink.setAttribute('target', '_blank')
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		let reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		let r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function formatDate(oldDate, fmt) {
	// oldDate is 13 digits
	// fmt for formatting, e.g. yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}

	const o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}

	const padLeftZero = (str) => ('00' + str).substr(str.length)

	Object.keys(o).forEach((k) => {
		if (new RegExp(`(${k})`).test(fmt)) {
			const str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	})

	return fmt
}

function formatTimestamp(timestamp) {
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	let date = new Date(timestamp)
	let month = months[date.getMonth()]
	let day = date.getDate()
	let year = date.getFullYear()
	return month + ' ' + day + ',' + year
}

const firebaseConfig = {
	apiKey: 'AIzaSyCsW0bEJs-FEMqWX2WJBEonSzdw2xf9vqw',
	authDomain: 'gb-group-1cf86.firebaseapp.com',
	projectId: 'gb-group-1cf86',
	storageBucket: 'gb-group-1cf86.appspot.com',
	messagingSenderId: '628677949465',
	appId: '1:628677949465:web:2623fadb0ba6ccdf9d9288',
	measurementId: 'G-MSHQPZW3HT',
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatDate,
	server,
	download,
	formatTimestamp,
	firebaseConfig,
}
