import { render, staticRenderFns } from "./domain-15pt.vue?vue&type=template&id=7877c144"
import script from "./domain-15pt.vue?vue&type=script&lang=js"
export * from "./domain-15pt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports