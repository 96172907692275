<template>
<section class="domain_content">
    <h1 class="title">
        Desbloquea las Funciones Adicionales de GB WhatsApp 2024
    </h1>

    <p class="pic-fit">
        <img src="@/assets/domain15-1.webp" alt="GB WhatsApp verificado" title="Desbloquea las Funciones Adicionales de GB WhatsApp 2024 1">
    </p>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="descargar" class="download-img" />
        <div class="download-name">Descargar GB WhatsApp</div>
    </div>

    <p>
        En la vida moderna, nadie puede vivir sin un teléfono móvil y una aplicación de mensajería en él. <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.whatsapp.com/">WhatsApp</a> es una de las aplicaciones de mensajería instantánea que te ayuda a mantener contacto con las personas. Hasta 2024, WhatsApp ha conseguido miles de millones de usuarios en todo el mundo. Luego, algunas aplicaciones modificadas de WhatsApp fueron desarrolladas. Las personas buscan más funciones y alguien resolvió sus necesidades.
    </p>

    <p>
        GB WhatsApp es una de las versiones modificadas más conocidas de WhatsApp original. No es tan popular, pero puede reducir los riesgos de prohibición en comparación con otras aplicaciones modificadas.
    </p>

    <TOC :tocList="toc" />

    <h2 id="tbc_1">
        {{toc[0]}}
    </h2>

    <p>
        GB WhatsApp está completamente desarrollado a partir de WhatsApp oficial. Los usuarios pueden usarlo como una nueva versión de WhatsApp para contactar con otros. Los mensajes enviados por los usuarios de GB WhatsApp también pueden ser recibidos por los usuarios de WhatsApp. Comparten el mismo sistema.
    </p>

    <p>
        Así que escribir mensajes, hacer videollamadas, enviar mensajes de audio, crear o unirse a grupos, son todas funciones disponibles en GB WhatsApp. En cuanto a las funciones adicionales, las hemos dividido en tres partes. Por favor, sigue leyendo.
    </p>

    <div class="features">
        <p class="pic-fit">
            <img src="@/assets/domain15-2.webp" alt="funciones personalizadas" title="Desbloquea las Funciones Adicionales de GB WhatsApp 2024 2">
        </p>

        <p>
            <strong>Temas</strong> <br>
            Una de las funciones populares de GB WhatsApp es la personalización de temas. Esto significa que los usuarios pueden cambiar los temas desde una tienda de temas que contiene más de 4000 temas. Los usuarios pueden diseñar el color, la fuente y el fondo del chat en GB WhatsApp.
        </p>

        <p>
            <strong>Icono de Ticks Azules</strong> <br>
            Es realmente increíble que el tradicional signo de mensajes leídos pueda ser cambiado en GB WhatsApp. Los desarrolladores proporcionan varios iconos para reemplazar los ticks azules.
        </p>

        <p>
            <strong>Marcar como No Leído</strong> <br>
            Los usuarios pueden marcar cualquier mensaje como no leído. Es conveniente para los usuarios leerlos más tarde cuando no tienen tiempo para leerlos al instante.
        </p>

        <p>
            <strong>Filtrar Mensajes</strong> <br>
            GB WhatsApp también añade una función de filtrado de mensajes para filtrar fácilmente mensajes de texto o imágenes específicos.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-3.webp" alt="funciones de privacidad" title="Desbloquea las Funciones Adicionales de GB WhatsApp 2024 3">
        </p>

        <p>
            <strong>Modo Fantasma</strong> <br>
            Está oculto en los ajustes de GB WhatsApp. Ve a la opción de privacidad y habilita esta opción. Aparecerá un icono de fantasma en la barra principal de la interfaz. Luego, puedes activar o desactivar el modo fácilmente.
        </p>

        <p>
            <strong>Bloquear Llamadas Entrantes</strong> <br>
            Como sugiere el nombre de la función, puedes bloquear las llamadas entrantes siempre que configures los límites.
        </p>

        <p>
            <strong>Ocultar Doble Ticks Azules</strong> <br>
            ¿Alguna vez te has preguntado si los dobles ticks pueden ocultarse? En GB WhatsApp, puedes hacerlo. Los ticks azules pueden ocultarse y puedes leer los mensajes libremente.
        </p>

        <p>
            <strong>Anti-Mensajes Eliminados/Rechazados</strong> <br>
            GB WhatsApp incluye una función para evitar que los mensajes eliminados o rechazados desaparezcan. Aún puedes ver los mensajes eliminados o rechazados en tu caja de chat. Y los mensajes eliminados también se pueden buscar en el historial de chat.
        </p>

        <p>
            <strong>Ocultar Rastreo de Vistas</strong> <br>
            Los usuarios pueden ver los estados de sus contactos sin notificarles. El rastreo de vistas se puede ocultar.
        </p>

        <p>
            <strong>Ocultar tu Estado</strong> <br>
            El "escribiendo..." o "grabando audio..." que aparece en la barra superior de la caja de chat a los contactos, grupos u otros, puede ocultarse en GB WhatsApp.
        </p>

        <p>
            <strong>Desactivar Reenvío</strong> <br>
            Cuando reenvíes un mensaje o foto desde otro chat, puedes eliminar la etiqueta de Reenvío.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-4.webp" alt="otras características" title="Desbloquea las Funciones Adicionales de GB WhatsApp 2024 4">
        </p>
        <ul>
            <li>Bloquea la app por seguridad</li>
            <li>Envía imágenes y videos de hasta 700 MB sin compresión</li>
            <li>Envía mensajes directamente a números de teléfono</li>
        </ul>
    </div>

    <h2 id="tbc_2">
        {{toc[1]}}
    </h2>
    <table>
        <tr>
            <th>Características</th>
            <th>GB WhatsApp</th>
            <th>WhatsApp</th>
        </tr>
        <tr>
            <td><strong>Modo Fantasma</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Marcar Mensajes Como No Leídos</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Ocultar Estado</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Cambiar Ticks Azules</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Ocultar Ticks Azules</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Bloquear Llamada de Video</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Modo DND</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Leer Mensajes Eliminados</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Cambiar Temas</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Cambiar Burbujas de Chat</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Efectos</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Marcar como No Leído</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Programar Mensajes</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Fotos Tamaño</strong></td>
            <td>100 MB</td>
            <td>100 MB</td>
        </tr>
        <tr>
            <td><strong>Calidad de Archivos Multimedia</strong></td>
            <td>Sin Compresión</td>
            <td>Compresión</td>
        </tr>
        <tr>
            <td><strong>Filtrar Mensajes</strong></td>
            <td>✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td><strong>Centro de Ayuda</strong></td>
            <td>❌</td>
            <td>✔️</td>
        </tr>
    </table>

    <h2 id="tbc_3">
        {{toc[2]}}
    </h2>

    <ul>
        <li><strong>Antes de Descargar Archivos APK:</strong><br>
            Los archivos APK de terceros serán detectados como una fuente desconocida por el sistema Android al instalar. Por lo tanto, los usuarios deben habilitar <strong>permitir desde esta fuente</strong>. También se puede encontrar bajo "Permisos especiales" en los teléfonos Android.
            <p class="pic-fit landscape">
                <img src="@/assets/domain15-5.webp" alt="instalar aplicaciones desconocidas" title="Desbloquea las características adicionales de GB WhatsApp 2024 5">
            </p>
        </li>
        <li><strong>Instalar GB WhatsApp:</strong><br>
            Después de confirmar el permiso para iniciar la instalación de GB WhatsApp, los usuarios pueden seguir la guía para completar el resto del proceso. Confirma que estás al tanto de los riesgos y otorga el permiso. Luego, podrás usar la aplicación.
        </li>
    </ul>

    <h2 id="tbc_4">
        {{toc[3]}}
    </h2>
    <p>
        Para transferir datos de WhatsApp a GB WhatsApp, primero debes hacer una copia de seguridad de los datos. Ve a Privacidad > Chat > Historial de Chat, luego establece la frecuencia de copia de seguridad en diaria. Generalmente, se cargará el dato en Google. Pero GB WhatsApp no puede acceder a datos de Google, ya que no es oficial.
    </p>

    <p>
        Sigue los pasos a continuación para completar la transferencia:
        <ul>
            <li><strong>Paso 1: </strong>Desactiva la copia de seguridad en la cuenta de Google. No hay un botón para esto. Pero puedes intentar desconectarte de WiFi o de la red móvil.</li>
            <li><strong>Paso 2: </strong>Ve al <strong>Administrador de Archivos</strong> en los teléfonos Android y navega a <strong>Android > Media > com.whatsapp > WhatsApp</strong>. Los datos se guardarán allí. Puedes verificar si el tiempo de actualización es el día en que hiciste la copia de seguridad.
                <p class="pic-fit landscape">
                    <img src="@/assets/domain15-6.webp" alt="carpeta" title="Desbloquea las características adicionales de GB WhatsApp 2024 6">
                </p>
            </li>
            <li><strong>Paso 3: </strong>Copia las carpetas <strong>Backups</strong>, <strong>Database</strong> y <strong>Media</strong> y pégalos en otra carpeta. Recuerda la ubicación de la carpeta en la que las pegaste. La necesitaremos en los siguientes pasos.</li>
            <li><strong>Paso 4: </strong>Desinstala WhatsApp. Si estás preocupado, puedes elegir no borrar la caché.</li>
            <li><strong>Paso 5: </strong>Crea una carpeta llamada <strong>com.gbwhatsapp</strong> dentro de <strong>Media</strong>. Crea una carpeta <strong>GB WhatsApp</strong> dentro de ella. Luego, mueve las carpetas <strong>Backups</strong>, <strong>Database</strong> y <strong>Media</strong> a ella.</li>
            <li><strong>Paso 6: </strong>Vuelve a la interfaz del teléfono y lanza GB WhatsApp, podrás restaurar los datos esta vez.</li>
        </ul>
    </p>

    <h2 id="tbc_5">
        {{toc[4]}}
    </h2>
    <ol>
        <li>Actualiza a la última versión desde GB WhatsApp: Verifica la versión actual de GB WhatsApp en la Información de la App, si hay una nueva versión lanzada, te notificará sobre la actualización.</li>
        <li>Descarga la última versión desde el sitio web: Visita este sitio web confiable de GB WhatsApp para descargar el archivo APK más reciente.</li>
    </ol>
    <h4 style="text-align:center;">
        Información de la App
    </h4>
    <table>
        <tr>
            <th>Nombre</th>
            <th>GB WhatsApp</th>
        </tr>
        <tr>
            <td>Tamaño</td>
            <td>77MB</td>
        </tr>
        <tr>
            <td>Versión</td>
            <td>18.10</td>
        </tr>
        <tr>
            <td>Actualización</td>
            <td>Hace 1 día</td>
        </tr>
        <tr>
            <td>Licencia</td>
            <td>Gratis</td>
        </tr>
    </table>
    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="descargar" class="download-img" />
        <div class="download-name">Descargar GB WhatsApp 18.10</div>
    </div>

    <h2 id="tbc_6">
        {{toc[5]}}
    </h2>
    <p>
        <strong>Cuentas de GB WhatsApp:</strong> Dado que WhatsApp oficial y GB WhatsApp comparten la misma cuenta, y el equipo de WhatsApp oficial ha mejorado su mecanismo anti-modificaciones. Los usuarios de GB WhatsApp pueden enfrentar el riesgo de bloqueos de cuenta. Es inevitable, por lo que muchos usuarios eligen usar un número de teléfono secundario para iniciar sesión en la aplicación GB WhatsApp.
    </p>
    <p>
        <strong>Fugas de Información Personal:</strong> Las preocupaciones varían entre diferentes personas. Algunos de ellos han instalado no solo una, sino varias versiones modificadas o crackeadas de aplicaciones. Algunos son muy cautelosos con respecto a la información personal. No hay evidencia directa que aclare el problema del robo de información.
    </p>

    <h2 id="tbc_7">
        {{toc[6]}}
    </h2>
    <FAQ :faqs="faqs" />

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import TOC from '@/components/TOC.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    ...{
        "metaInfo": {
            "title": "Safe Download GB WhatsApp 2024 to Unlock Extra Features",
            "meta": [{
                "name": "description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }, {
                "name": "title",
                "content": "GBWhatsApp"
            },
             {
                "property": "og:title",
                "content": "GBWhatsApp"
            }, {
                "property": "og:description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapps.homes/es/"
            }]
        }
    },
    components: {
        TOC,
        FAQ
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            language: 'es',
            toc: [
                '¿Por qué la gente elige usar GB WhatsApp?',
                'Comparación entre GB WhatsApp y WhatsApp',
                'Descargar e instalar GB WhatsApp',
                'Transferir datos de WhatsApp a GB WhatsApp',
                'Métodos para actualizar el archivo APK de GB WhatsApp',
                'Problemas de seguridad: ¿Es GB WhatsApp seguro?',
                'Preguntas frecuentes'
            ],
            faqs: [{
                    question: '¿Qué hay de nuevo en la última versión de GB WhatsApp?',
                    answer: 'La última versión de GB WhatsApp ha corregido algunos errores y mejorado la protección contra prohibiciones.',
                },
                {
                    question: '¿Puedo usar la versión antigua de GB WhatsApp?',
                    answer: 'Si estás usando la versión antigua, puedes mantenerla. Pero no olvides hacer una copia de seguridad de los datos regularmente. Si es demasiado antigua, puedes actualizarla a la versión más reciente.',
                },
                {
                    question: 'GB WhatsApp para iPhone',
                    answer: 'GB WhatsApp no es compatible con el sistema iOS. Los usuarios de iPhone no pueden usarlo. Si estás interesado, puedes buscar cómo instalar un sistema Android en YouTube.',
                },
                {
                    question: '¿Qué es Blue WhatsApp Plus?',
                    answer: 'El equipo de BlueMods desarrolló Blue WhatsApp Plus con más funciones que el WhatsApp oficial. Es otra versión MOD de la aplicación. Puedes encontrar características similares, pero con pequeñas diferencias en el logo y la interfaz.',
                },
            ]
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
